import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  list: [],
  allCount: null,

  detailForEdit: null,
  detail: null,
};

export const RobotInstrument = createSlice({
  initialState,
  name: 'RobotInstrument',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getRobotInstrumentList.matchPending, (state, action) => {
        state.list = [];
        state.allCount = null;
      })
      .addMatcher(api.endpoints.getRobotInstrumentList.matchFulfilled, (state, action) => {
        state.list = action.payload.results;
        state.allCount = action.payload.count;
      })

      .addMatcher(api.endpoints.getRobotInstrumentDetail.matchPending, (state, action) => {
        state.detail = null;
      })
      .addMatcher(api.endpoints.getRobotInstrumentDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })

      .addMatcher(api.endpoints.getRobotInstrumentForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.getRobotInstrumentForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })

      .addMatcher(api.endpoints.updateRobotInstrumentChange.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default RobotInstrument.reducer;
