import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"

import { useLazyGetDetailQuery } from 'models/Account/api'
import { DetailPage, } from 'layout'
import { AccountDetail } from 'models/Account/components'


const AccountDetailPage = () => {
  const { accountId } = useParams();

  const detail = useSelector(state => state.Account.detail);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();

  return (
    <DetailPage
      objectId={accountId}
      detail={detail}
      title={detail?.name || detail?.id || '-'}
      showBack={true}
      basePath={'/exchange/'}

      getObjectDetail={getObjectDetail}
      getObjectDetailProps={getObjectDetailProps}

      actions={[]}
      render={(editFormState, onFormClose) => (
        <>
          <AccountDetail accountId={accountId} detail={detail} />
        </>
      )}
    />
  );
}

export default AccountDetailPage
