import React from 'react'
import { Form } from 'antd'

import InstrumentSelect from '../InstrumentSelect'


const InstrumentSelectField = ({ name, label, required, disabled }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <InstrumentSelect required={required} disabled={disabled} />
    </Form.Item>
  );
}

InstrumentSelectField.getQueryParams = (key, value) => {
  return { [key]: value };
}

InstrumentSelectField.getValue = (values) => {
  return values;
}

InstrumentSelectField.getDisplayValue = (values, config) => {
  return values
};

export default InstrumentSelectField
