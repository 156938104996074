import { useEffect, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Page } from 'layout'
import { useLazyGetSimpleListQuery } from 'models/Exchange/api'
import { InstrumentList } from 'models/Instrument/components'

import './styles.css'


const InstrumentPage = () => {
  const { exchangeCode } = useParams();
  const navigate = useNavigate();
  const [getExchangeList, getExchangeListProps] = useLazyGetSimpleListQuery();

  useEffect(() => {
    getExchangeList({ isForRobot: true });
  }, [getExchangeList]);

  useEffect(() => {
    if (!exchangeCode && getExchangeListProps.isSuccess) {
      const exchangeCode = getExchangeListProps.data.results[0]?.code;
      navigate(`/instrument/by/${exchangeCode}/`);
    }
    // eslint-disable-next-line
  }, [getExchangeListProps.isSuccess, getExchangeListProps.data, exchangeCode]);

  const exchangeTabs = useMemo(() => {
    if (getExchangeListProps.isSuccess) {
      return getExchangeListProps.data.results.map(item => ({ label: item.name, key: `${item.code}/` }))
    }
    return []
  }, [getExchangeListProps.isSuccess, getExchangeListProps.data]);

  return (
    <Page
      title="Инструменты"
      basePath="/instrument/by/"
      tabs={exchangeTabs}
      isLoading={getExchangeListProps.isFetching}
    >
      {exchangeCode && <InstrumentList filterParams={{ exchange: exchangeCode }} />}
    </Page>
  );
};

export default InstrumentPage
