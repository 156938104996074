import { numberWithCommas } from 'utils/format'
import DateTimeValue from 'components/DateTimeValue'

import './styles.css'


const LastPrice = ({ lastPrice, instrument = null }) => {
  if (!lastPrice?.price) return;

  let currency = null;
  if (instrument?.instrumentType === 'future') {
    currency = 'пт.';
  } else if (instrument?.currency) {
    currency = instrument.currency;
  }

  if (currency === 'rub') {
    currency = '₽';
  }


  return (
    <div className="lastPrice">
      <div className="lastPrice__price">
        {numberWithCommas(lastPrice.price)} 
        {currency && <span>{currency}</span>}
      </div>
      <DateTimeValue
        className="lastPrice__time"
        date={lastPrice?.time}
        format="DD.MM HH:mm:ss"
        size={10}
      />
    </div>
  );
};


export default LastPrice
