import { simpleNumber } from 'utils/format'
import { SelectField } from 'components/FormFields'


const MainFieldSet = ({ detailForEdit, initials }) => {
  const instruments = detailForEdit?.instruments || [];
  console.log(instruments);

  return (
    <>
      <SelectField
        label="Инструмент"
        name="ticker"
        required={true}
        options={instruments.map(item => {
          const lastPrice = item.lastPrice ? simpleNumber(item.lastPrice.price): '-';
          return { value: item.ticker, label: `[${item.ticker}] ${item.name} ${item.expirationDate}  (посл. сделка: ${lastPrice})` };
        })}
      />
    </>
  );
};

export default MainFieldSet;
