import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import { Tag } from 'antd'

import { numberWithCommas } from 'utils/format'
import { useLazyGetListQuery, useUpdateMutation, useDeleteMutation, useBatchDeleteMutation } from 'models/RobotGroup/api'
import ActionButton from 'components/ActionButton'
import TableList from 'components/TableList'
import { FilterItemBool } from 'components/Filter'
import OnOff from 'components/OnOff'
import RobotGroupValue from '../RobotGroupValue'


const defaultColumnList = ['name', 'ticker', 'startDirection', 'profit', 'ots', 'koef', 'time', 'flipPause', 'startTimes', 'isOn', 'actions'];


const RobotGroupList = forwardRef(({ title, columnList, topActions, onEdit, showSearch, showFilter, filterParams }, ref) => {
  const tableRef = useRef(null);

  const activeBroker = useSelector(state => state.Broker.active);
  const objectList = useSelector(state => state.RobotGroup.list);
  const allCount = useSelector(state => state.RobotGroup.allCount);
  const [getObjectList, getObjectListProps] = useLazyGetListQuery();
  const [itemUpdate, itemUpdateProps] = useUpdateMutation();
  const [itemDelete, itemDeleteProps] = useDeleteMutation();
  const [batchDelete, batchDeleteProps] = useBatchDeleteMutation();

  const onDelete = (detail) => {
    itemDelete({ brokerId: activeBroker.id, id: detail.id })
  }

  useEffect(() => {
    if (itemDeleteProps.isSuccess) {
      loadData();
    }
    // eslint-disable-next-line
  }, [itemDeleteProps.isSuccess]);

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      loadData();
    }
    // eslint-disable-next-line
  }, [itemUpdateProps.isSuccess]);

  const fields = {
    id: {
      title: 'ID',
      sorter: true,
      width: 80,
    },
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<RobotGroupValue detail={item} asLink={true} isBack={true} />),
    },
    ticker: {
      title: 'Инструмент',
      sorter: true,
      width: 110,
      render: (val, item) => (<Tag color="blue">{item.instrument?.ticker}</Tag>),
    },
    startDirection: {
      title: 'Стартовая позиция',
      width: 90,
      render: (val, item) => val === 1 ? 'long' : 'short',
    },
    profit: {
      title: 'Профит',
      width: 120,
      render: (val, item) => (numberWithCommas(val, 2)),
    },
    ots: {
      title: 'Отступ',
      width: 90,
      render: (val, item) => (`${numberWithCommas(val, 2)} %`),
    },
    koef: {
      title: 'Коэфф. увел.',
      width: 90,
      render: (val, item) => (numberWithCommas(val, 2)),
    },
    time: {
      title: 'Время перв. сбора',
      width: 100,
      render: (val, item) => (`${val} мин.`),
    },
    flipPause: {
      title: 'Пауза при стоп-перев.',
      width: 100,
      render: (val, item) => (`${val} мин.`),
    },
    startTimes: {
      title: 'Время запуска',
      width: 130,
      render: (val, item) => val.map(timeStr => (
        <div key={timeStr}>{timeStr}</div>
      )),
    },
    isOn: {
      title: 'Включено',
      sorter: true,
      width: 90,
      render: (val, item) => (
        <OnOff
          detail={item}
          fieldName="isOn"
          addData={{ broker: activeBroker?.id }}
          itemUpdate={itemUpdate}
          itemUpdateProps={itemUpdateProps}
        />
      ),
    },
    actions: {
      title: '',
      align: 'right',
      width: 50,
      render: (val, item) => (
        <ActionButton
          detail={item}
          actions={[
            { name: 'edit', action: (detail) => onEdit(detail.id) },
            { name: 'delete', action: onDelete }
          ]}
        />
      ),
    },
  };

  function loadData() {
    tableRef.current && tableRef.current.loadData();
  }
  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      title={title}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectList}
      allCount={allCount}
      queryKey="RobotGroup"

      getObjectList={(params) => getObjectList({ brokerId: activeBroker?.id, params })}
      getObjectListProps={getObjectListProps}
      batchDelete={(selected) => batchDelete({ brokerId: activeBroker.id, selected })}
      batchDeleteProps={batchDeleteProps}
      onEdit={onEdit}

      topActions={topActions}
      showSearch={showSearch}
      rowSelection={true}

      filterFields={showFilter && [
        {
          label: 'Активно',
          field: 'isActive',
          component: FilterItemBool,
        },
      ]}

      rowClassName={(item) => {
        if (item?.changeStatus !== 'active') return 'archive';
        return '';
      }}
    />
  );
});

export default RobotGroupList;
