import React from 'react'
import { Form, Radio } from 'antd'


const FilterItemRadioButtons = ({ label, field, options, size }) => {
  return (
    <Form.Item name={field} label={label}>
      <Radio.Group buttonStyle="solid" optionType="button" size={size}>
        <Radio.Button value="">Все</Radio.Button>
        {options.map(opt => <Radio.Button key={opt.value} value={opt.value}>{opt.label}</Radio.Button>)}
      </Radio.Group>
    </Form.Item>
  );
}

FilterItemRadioButtons.getQueryParams = (key, value) => {
  return { [key]: value };
}

FilterItemRadioButtons.getValue = (values) => {
  return values[0] || '';
}

FilterItemRadioButtons.getDisplayValue = (value, config) => {
  const result = config.options.find(opt => {
    return opt.value === value
  })
  return result ? result.label : value;
}

export default FilterItemRadioButtons;
