import React from 'react'
import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'

import Navigation from 'layout/Navigation'
import AppHeader from './AppHeader'

import "./styles.css"


const AppLayout = () => {
  return (
    <Layout className="app">
      <AppHeader />

      <Layout.Content className="appContent" id="appContent">
        <Outlet />
      </Layout.Content>

      <Navigation isMobile={true} />
    </Layout>
  );
};

export default AppLayout;
