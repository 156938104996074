import { useSelector } from 'react-redux'
import moment from 'moment'

import RobotInstrumentApi from 'models/RobotInstrument/api'
import Form from 'components/Form'
import MainFieldSet from './MainFieldSet'


const RobotInstrumentChangeForm = ({ visible, objectId, initials, onClose }) => {
  const detailForEdit = useSelector(state => state.RobotInstrument.detailForEdit);

  const [getObjectDetail, getObjectDetailProps] = RobotInstrumentApi.useLazyGetRobotInstrumentForEditQuery();
  const [update, updateProps] = RobotInstrumentApi.useUpdateRobotInstrumentChangeMutation();

  const onFinish = (values) => {
    const data = { ...values, old_ticker: objectId };
    console.log(data);
    update(data);
  };

  return (
    <Form
      name=""
      title={`Замена инструмента ${objectId}`}
      width={700}
      visible={visible}
      objectId={objectId}
      detailForEdit={detailForEdit}
      getObjectDetail={(ticker) => getObjectDetail({ ticker })}
      getObjectDetailProps={getObjectDetailProps}
      updateProps={updateProps}

      onFinish={onFinish}
      onClose={onClose}
      initialValues={initials}

      prepareFormValues1={(detail) => {
        return { ...detail, instrument: detail.instrument.ticker, startTimes: detail.startTimes.map(timeStr => moment(timeStr, 'HH:mm')) }
      }}
    >
      <MainFieldSet detailForEdit={detailForEdit} initials={initials} />
    </Form>
  );
}


export default RobotInstrumentChangeForm
