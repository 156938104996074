import { useState, useEffect } from 'react'
import useWebSocket, { ReadyState }  from 'react-use-websocket'

import { WS_URL } from 'config'


export function useRobotWS(robotIds) {
  const [changedRobot, setChangedRobot] = useState(null);

  const { readyState, sendMessage } = useWebSocket(`${WS_URL}robot/`, {
    onMessage: (event) => {
      const msg = JSON.parse(event.data);
      if (msg && msg?.type === 'robot') {
        const robot = msg.robot;
        if (robotIds.includes(robot.id)) {
          setChangedRobot(robot);
        }
      }
    },
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const message = {
        type: 'subscribeRobots',
        robot_ids: robotIds,
      }
      sendMessage(JSON.stringify(message));
    }
  }, [readyState, sendMessage, robotIds]);

  return { changedRobot };
}
