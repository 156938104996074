import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'

import { useRobotWS } from 'models/Robot/useHooks'
import RobotInstrumentValue from 'models/RobotInstrument/components/RobotInstrumentValue'
import LastPrice from 'models/Instrument/components/LastPrice'
import { useInstrumentLastPriceWS } from 'models/Instrument/useHooks'

import Fieldset from 'components/Fieldset'
import Descriptions from 'components/Descriptions'
import DateTimeValue from 'components/DateTimeValue'
import NumberValue from 'components/NumberValue'
import RobotStatus from '../RobotStatus'
import RobotPosition from '../RobotPosition'
import RobotProfitStatus from '../RobotProfitStatus'
import RobotCurrentProfit from '../RobotCurrentProfit'


const RobotDetail = ({ detailId, detail }) => {
  const { changedRobot } = useRobotWS([detailId]);
  const robotDetail = changedRobot?.id === detailId ? changedRobot : detail;

  const instrument = robotDetail?.instrument;

  const currency = instrument?.instrumentType === 'future' ? 'пт.' : instrument?.currency;

  const { instrumentLastPrice } = useInstrumentLastPriceWS(instrument?.ticker ? [instrument?.ticker] : []);
  const lastPrice = instrumentLastPrice || instrument?.lastPrice;

  const before = detail?.before;

  return (
    <>
      <Fieldset>
        <Descriptions size="sm">
          <Descriptions.Item xs={12} md={7} label="Инструмент">
            <RobotInstrumentValue detail={robotDetail} asLink={true} isBack={true} />
          </Descriptions.Item>

          <Descriptions.Item label="Посл. сделка" xs={12} md={4}>
            <LastPrice lastPrice={lastPrice} currency={detail?.currency} />
          </Descriptions.Item>

          <Descriptions.Item label="Статус" xs={8} md={5}>
            <RobotStatus status={robotDetail?.status} changeStatus={robotDetail?.changeStatus} />
          </Descriptions.Item>

          <Descriptions.Item label="Дата запуска" xs={8} md={4}>
            <DateTimeValue size="12" date={robotDetail?.dateStarted} format='DD.MM.YYYY HH:mm' />
          </Descriptions.Item>

          <Descriptions.Item label="Дата заверш." xs={8} md={4}>
            <DateTimeValue size="12" date={robotDetail?.dateEnded} format='DD.MM.YYYY HH:mm' />
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Fieldset title="Настройки">
        <Descriptions size="sm">
          <Descriptions.Item label="Стартовая позиция" xs={8} md={4}>
            {robotDetail?.startDirection === 1 ? 'long' : 'short'}
          </Descriptions.Item>

          <Descriptions.Item label="Профит" xs={8} md={4}>
            <NumberValue value={robotDetail?.plannedProfit} currency={currency} mark={false} />
          </Descriptions.Item>

          <Descriptions.Item label="Отступ" xs={8} md={4}>
            <NumberValue value={robotDetail?.ots} currency="%" mark={false} />
          </Descriptions.Item>

          <Descriptions.Item label="Коэф. увеличения" xs={8} md={4}>
            <NumberValue value={robotDetail?.koef} mark={false} />
          </Descriptions.Item>

          <Descriptions.Item label="Время перв. сбора" xs={8} md={4}>
            <NumberValue value={robotDetail?.timeTracking} currency="мин" mark={false} />
          </Descriptions.Item>

          <Descriptions.Item label="Пауза при стоп-перев." xs={8} md={4}>
            <NumberValue value={robotDetail?.flipPause} currency="мин" mark={false} />
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Row gutter={[16]}>
        <Col xs={24} md={8}>
          <Row gutter={[16]}>
            <Col xs={14} md={14}>
              <Fieldset title={`Информация ${robotDetail?.id}`}>
                {robotDetail && <RobotProfitStatus robot={robotDetail} />}
              </Fieldset>
            </Col>

            {before &&
              <Col xs={10} md={10}>
                <Fieldset title="Смена инстр.">
                  <Descriptions size="sm">
                    <Descriptions.Item label="Цена покупки" xs={24} md={24}>
                      <NumberValue value={robotDetail?.changeBuyPrice} mark={false} /> (<NumberValue value={robotDetail?.changeBuyPrice - before?.changeSellPrice} mark={true} />)
                    </Descriptions.Item>
                  </Descriptions>
                </Fieldset>
              </Col>}
          </Row>

          {before &&
            <Row gutter={[16]} style={{ opacity: '.66' }}>
              <Col xs={14} md={14}>
                <Fieldset title={<Link to={`/robot/${before?.id}/`}>Информация {before?.id}</Link>}>
                  {robotDetail && <RobotProfitStatus robot={before} />}
                </Fieldset>
              </Col>

              <Col xs={10} md={10}>
                <Fieldset title="Смена инстр.">
                  <Descriptions size="sm">
                    <Descriptions.Item label="Цена продажи" xs={24} md={24}>
                      <NumberValue value={before?.changeSellPrice} mark={false} />
                    </Descriptions.Item>
                  </Descriptions>
                </Fieldset>
              </Col>
            </Row>
          }
        </Col>

        <Col xs={12} md={6}>
          <Fieldset title="Позиция">
            <Descriptions size="sm">
              <Descriptions.Item label="Позиция" span={24}>
                {robotDetail && <RobotPosition robot={robotDetail} />}
              </Descriptions.Item>
              <Descriptions.Item label="Цена входа" span={24}>
                <NumberValue value={robotDetail?.price} currency={currency} mark={false} />
              </Descriptions.Item>
              <Descriptions.Item label="Цена проф." span={24}>
                <NumberValue value={robotDetail?.priceProfit} currency={currency} mark={false} />
              </Descriptions.Item>
            </Descriptions>
          </Fieldset>
        </Col>

        <Col xs={12} md={5}>
          <Fieldset title="Стоп-переворот">
            <Descriptions size="sm">
              <Descriptions.Item label="Цена стоп-перев." span={24}>
                <NumberValue value={robotDetail?.priceFlip} currency={currency} mark={false} />
              </Descriptions.Item>
              <Descriptions.Item label="Кол-во" span={24}>
                <NumberValue value={robotDetail?.flipCount} mark={false} />
              </Descriptions.Item>
            </Descriptions>
          </Fieldset>
        </Col>

        <Col xs={12} md={5}>
          <Fieldset title="Профит/Убыток">
            {robotDetail && <RobotCurrentProfit robot={robotDetail} />}
          </Fieldset>
        </Col>
      </Row>
    </>
  );
}

export default RobotDetail
