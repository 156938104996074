import React, { useMemo } from 'react'
import { UserOutlined } from '@ant-design/icons'
import classNames from 'classnames'

import './styles.css'


const Avatar = ({ image, alt, className, style, size = 32 }) => {
  const cls = ['avatar', className];

  const src = useMemo(() => {
    if (Array.isArray(image)) {
      return image[0]?.url;
    } else if (image?.url) {
      return image.url;
    }
    return image;
  }, [image])

  let mainStyle = {};
  if (size) {
    mainStyle['width'] = `${size}px`;
    mainStyle['fontSize'] = `${size / 1.2}px`;
  }

  mainStyle = Object.assign(
    mainStyle,
    style || {},
  );

  const cx = classNames(cls);

  return (
    <div className={cx} style={mainStyle}>
      {src ?
        <div className="avatarInner"><img src={src} alt={alt} /></div>
        :
        <div className="avatarInner avatarInner__empty"><UserOutlined /></div>
      }
    </div>
  );
};

export default Avatar;
