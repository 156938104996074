import { useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'antd'

import RobotInstrumentApi from 'models/RobotInstrument/api'
//import { useInstrumentLastPriceWS } from 'models/Instrument/useHooks'
import LastPrice from 'models/Instrument/components/LastPrice'
import ActionButton from 'components/ActionButton'
import TableList from 'components/TableList'
import { FilterItemBool } from 'components/Filter'
import DateTimeValue from 'components/DateTimeValue'
import RobotInstrumentValue from '../RobotInstrumentValue'
import RobotInstrumentStatus from '../RobotInstrumentStatus'

const defaultColumnList = ['instrument__name', 'status', 'mobileExpirationDate', 'expirationDate', 'lastPrice'];


const RobotInstrumentList = forwardRef(({ title, columnList, topActions, onEdit, showSearch, showFilter, filterParams, onChangeInstrument }, ref) => {
  const tableRef = useRef(null);

  const objectList = useSelector(state => state.RobotInstrument.list);
  const allCount = useSelector(state => state.RobotInstrument.allCount);
  const [getObjectList, getObjectListProps] = RobotInstrumentApi.useLazyGetRobotInstrumentListQuery();
  /*const [itemUpdate, itemUpdateProps] = RobotInstrumentApi.useUpdateRobotInstrumentMutation();

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      loadData();
    }
    // eslint-disable-next-line
  }, [itemUpdateProps.isSuccess]);*/

  //const tickers = objectList.map(item => item.instrument.ticker);
  //const { instrumentLastPrice } = useInstrumentLastPriceWS(tickers);

  const objectListWithParams = objectList;
  /*
  const objectListWithParams =
    !instrumentLastPrice ?
      objectList :
      objectList.map(item => {
        if (instrumentLastPrice.ticker === item.instrument.ticker) {
          return { ...item, instrument: { ...item.instrument, lastPrice: instrumentLastPrice } };
        }
        return item;
      });*/

  const fields = {
    instrument__name: {
      title: 'Инструмент',
      sorter: true,
      render: (val, item) => (<RobotInstrumentValue detail={item} asLink={true} isBack={true} style1={{ maxWidth: '110px' }} />),
    },
    status: {
      title: 'Статус',
      sorter: false,
      width: 140,
      responsive: ["md"],
      render: (val, item) => <RobotInstrumentStatus status={item.changeStatus} />,
    },
    mobileExpirationDate: {
      title: 'Дата погаш.',
      width: 100,
      responsive: ["xs"],
      render: (val, item) => (
        <>
          <RobotInstrumentStatus status={item.changeStatus} style={{ marginBottom: '4px' }} />
          <DateTimeValue date={item.instrument.expirationDate} format="DD.MM.YYYY" size={10} />
          {item?.changeStatus === 'active' && item?.allow_change &&
            <Button type="dashed" danger size="small" onClick={() => onChangeInstrument(item)}>Заменить</Button>
          }
        </>
      ),
    },
    expirationDate: {
      title: 'Дата погашения',
      width: 140,
      responsive: ["md"],
      render: (val, item) => (
        <>
          <DateTimeValue date={item.instrument.expirationDate} format="DD.MM.YYYY" />
          {item?.changeStatus === 'active' && item?.allow_change &&
            <Button type="dashed" danger size="small" onClick={() => onChangeInstrument(item)}>Заменить</Button>
          }
        </>
      ),
    },
    lastPrice: {
      title: 'Посл.сделка',
      width: 100,
      align: 'right',
      render: (val, item) => <LastPrice instrument={item.instrument} lastPrice={item.instrument.lastPrice} />,
    },
    actions: {
      title: ' ',
      align: 'right',
      width: 30,
      render: (val, item) => (
        <ActionButton
          detail={item}
          actions={[
            { name: 'edit', action: (detail) => onEdit(detail.id) },
          ]}
        />
      ),
    },
  };

  function loadData() {
    tableRef.current && tableRef.current.loadData();
  }
  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      title={title}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectListWithParams}
      allCount={allCount}
      queryKey="RobotInstrument"

      getObjectList={(params) => getObjectList({ params })}
      getObjectListProps={getObjectListProps}
      onEdit={onEdit}

      topActions={topActions}
      showSearch={showSearch}
      rowSelection={false}

      filterFields={showFilter && [
        {
          label: 'Активно',
          field: 'isActive',
          component: FilterItemBool,
        },
      ]}
    />
  );
});

export default RobotInstrumentList;
