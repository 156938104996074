import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"
import { Button } from 'antd'

import { useLazyGetDetailQuery } from 'models/Instrument/api'
import { DetailPage } from 'layout'
import { RobotGroupList, RobotGroupForm } from 'models/RobotGroup/components'


const InstrumentDetailRobotsPage = () => {
  const { ticker } = useParams();

  const detail = useSelector(state => state.Instrument.detail);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();

  const ref = useRef(null);
  const [editRobotGroupFormState, setEditRobotGroupFormState] = useState({});

  const onEditRobotGroup = (objectId = null) => {
    setEditRobotGroupFormState({ visible: true, objectId });
  };



  return (
    <DetailPage
      objectId={ticker}
      detail={detail}
      title={`${ticker}: ${detail?.name}`}
      showBack={true}
      basePath={`/instrument/${ticker}/`}

      getObjectDetail={(ticker) => getObjectDetail({ ticker })}
      getObjectDetailProps={getObjectDetailProps}

      actions={[]}

      tabs={[
        { label: 'Основное', key: '' },
        { label: 'Роботы', key: 'robots' },
      ]}

      render={(editFormState, onFormClose) => (
        <>
          <RobotGroupList
            title="Группы роботов"
            topActions={[
              <Button type="primary" onClick={() => onEditRobotGroup()}>Добавить робота</Button>
            ]}
            onEdit={onEditRobotGroup}
            ref={ref}
            filterParams={{ instrument: ticker }}
          />

          <RobotGroupForm
            initials={{ instrument: detail?.ticker }}
            onClose={(isUpdated) => {
              setEditRobotGroupFormState({});
              isUpdated && ref.current && ref.current.loadData();
            }}
            {...editRobotGroupFormState}
          />
        </>
      )}
    />
  );
}

export default InstrumentDetailRobotsPage
