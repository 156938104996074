import { useEffect } from 'react'
import { Link } from "react-router-dom"
import { useSelector } from 'react-redux'
import { Tag } from 'antd'

import { numberWithCommas } from 'utils/format'
import { useUpdateMutation, useLazyGetDetailQuery } from 'models/RobotGroup/api'
import Fieldset from 'components/Fieldset'
import Descriptions from 'components/Descriptions'
import DateTimeValue from 'components/DateTimeValue'
import OnOff from 'components/OnOff'


const RobotGroupDetail = ({ detailId, detail }) => {
  const [itemUpdate, itemUpdateProps] = useUpdateMutation();
  const [getObjectDetail] = useLazyGetDetailQuery();

  const activeBroker = useSelector(state => state.Broker.active);

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      getObjectDetail({ brokerId: activeBroker?.id, id: detailId });
    }
    // eslint-disable-next-line
  }, [detailId, itemUpdateProps.isSuccess]);

  const startTimes = detail?.startTimes || [];

  return (
    <>
      <Fieldset>
        <Descriptions size="sm">
          <Descriptions.Item span={6} label="Активно">
            <OnOff
              detail={detail}
              fieldName="isOn"
              addData={{ broker: activeBroker?.id }}
              itemUpdate={itemUpdate}
              itemUpdateProps={itemUpdateProps}
            />
          </Descriptions.Item>

          <Descriptions.Item span={6} label="Инструмент">
            <Link to={`/instrument/${detail?.ticker}/`}><Tag color="blue">{detail?.ticker}</Tag></Link>
          </Descriptions.Item>

          <Descriptions.Item span={12} label="Дата создания">
            <DateTimeValue date={detail?.dateCreated} />
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Fieldset title="Основное" size="sm">
        <Descriptions>
          <Descriptions.Item label="Стартовая позиция" span={4}>{detail?.startPosition}</Descriptions.Item>
          <Descriptions.Item label="профит" span={4}>{numberWithCommas(detail?.profit, 2)}</Descriptions.Item>
          <Descriptions.Item label="отступ" span={4}>{numberWithCommas(detail?.ots, 2)}%</Descriptions.Item>
          <Descriptions.Item label="коэффициент увеличения" span={4}>{detail?.koef}</Descriptions.Item>
          <Descriptions.Item label="время первоначального сбора" span={4}>{detail?.time} мин</Descriptions.Item>
          <Descriptions.Item label="пауза при стоп-перевороте" span={4}>{detail?.flipPause} мин</Descriptions.Item>

          <Descriptions.Item label="Время запуска" span={21}>
            {startTimes.map(time => (
              <span key={time} style={{ marginRight: '8px' }}>{time}</span>
            ))}
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>
    </>
  );
}

export default RobotGroupDetail
