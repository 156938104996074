import React, { useEffect } from 'react';
import { Spin } from 'antd'


const ListLoadComponent = ({ getObjectList, getObjectListProps, filter, render, onLoaded }) => {
  const items = getObjectListProps.data || [];

  useEffect(() => {
    const params = Object.assign(filter || {}, { limit: 5000 });
    getObjectList(params);
  }, [filter, getObjectList]);

  useEffect(() => {
    if (getObjectListProps.isSuccess) {
      onLoaded && onLoaded(getObjectListProps.data.results);
    }
  }, [onLoaded, getObjectListProps.isSuccess, getObjectListProps.data]);

  return <Spin spinning={getObjectListProps.isFetching}>{render({ items })}</Spin>;
};

export default ListLoadComponent;
