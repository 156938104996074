import { api } from 'redux/api/api'


const AccountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountList: builder.query({
      query: (params = null) => {
        return {
          url: 'broker/account/',
          params: params,
        }
      }
    }),
    getAccountSimpleList: builder.query({
      query: (params = null) => ({
        url: 'broker/account/',
        params: params,
      }),
    }),
    getAccountDetail: builder.query({
      query: (id) => ({
        url: `broker/account/${id}/`,
      }),
    }),
    getAccountForEdit: builder.query({
      query: (id) => ({
        url: `broker/account/${id}/`,
      }),
    }),

    updateAccount: builder.mutation({
      query(values) {
        const url = values.id ? `broker/account/${values.id}/`: 'broker/account/';
        const method = values.id ? `PUT`: 'POST';
        return {
          url: url,
          method: method,
          body: values,
        };
      },
    }),
    deleteAccount: builder.mutation({
      query(id) {
        return {
          url: `broker/account/${id}/`,
          method: 'DELETE',
        };
      },
    }),
    batchDeleteAccount: builder.mutation({
      query(selected) {
        return {
          url: 'broker/account/delete/',
          method: 'POST',
          body: { selected },
        };
      },
    }),
  }),
});

export default AccountApi;

const {
  useLazyGetAccountListQuery,
  useLazyGetAccountSimpleListQuery,
  useLazyGetAccountDetailQuery,
  useLazyGetAccountForEditQuery,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
  useBatchDeleteAccountMutation,
} = AccountApi;

export {
  useLazyGetAccountListQuery as useLazyGetListQuery,
  useLazyGetAccountSimpleListQuery as useLazyGetSimpleListQuery,
  useLazyGetAccountDetailQuery as useLazyGetDetailQuery,
  useLazyGetAccountForEditQuery as useLazyGetForEditQuery,
  useUpdateAccountMutation as useUpdateMutation,
  useDeleteAccountMutation as useDeleteMutation,
  useBatchDeleteAccountMutation as useBatchDeleteMutation,
};
