import React from 'react'

import DetailValue from 'components/DetailValue'


const RobotGroupValue = ({ detail, asLink, isBack }) => {
  return (
    <>
      <DetailValue 
        linkUrl={asLink && `/robotGroup/${detail.id}/`} 
        isBack={isBack} 
        render={() => (
          <div className="valueFlex">
            Группа №{detail?.id}
          </div>
        )}
      />
    </>
  );
};


export default RobotGroupValue
