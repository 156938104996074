import { simpleNumber } from 'utils/format'

import './styles.css'


const RobotProfitChart = ({ robot, width, padding }) => {
  if (!robot || robot.direction === 0) return null;

  const WIDTH2 = (width - 2 * padding) / 2;
  const WIDTH3 = WIDTH2 + padding / 2;

  const lastPriceStr = simpleNumber(robot.lastPrice, null, 3);
  const positionPriceStr = simpleNumber(robot.price, null, 3);
  const priceProfitStr = simpleNumber(robot.priceProfit, null, 3);
  const priceFlipStr = simpleNumber(robot.priceFlip, null, 3);

  const lastPrice = parseFloat(robot.lastPrice);
  const priceProfit = parseFloat(robot.priceProfit);
  const positionPrice = parseFloat(robot.price);
  const priceFlip = parseFloat(robot.priceFlip);
  let dx = 0;
  let color = 'transparent';
  if (robot.direction === 1) {
    if (lastPrice > positionPrice) {
      dx = WIDTH2 / Math.abs(priceProfit - positionPrice);
      color = 'green';
    } else {
      dx = - WIDTH2 / Math.abs(priceFlip - positionPrice);
      color = 'red';
    }
  } else {
    if (lastPrice < positionPrice) {
      dx = - WIDTH2 / Math.abs(priceProfit - positionPrice);
      color = 'green';
    } else {
      dx = WIDTH2 / Math.abs(priceFlip - positionPrice);
      color = 'red';
    }
  }

  //const dx = 128 / Math.abs(robot.priceProfit - robot.priceFlip);
  let x = dx * Math.abs(robot.lastPrice - robot.price);
  if (x > WIDTH3) {
    x = WIDTH3;
  } else if (x < -WIDTH3) {
    x = -WIDTH3;
  }

  const fillWidth = Math.abs(x);
  const fillTranslateX = x < 0 ? '-100%' : 0;

  return (
    <div className={`profitChart dir${robot.direction}`} style={{ width: `${width}px`, maxWidth: `${width}px` }}>
      {robot.direction === 1 ?
        <>
          <div className="profitChartItem profitChartItemStart" style={{ left: `${padding}px` }}>
            <div className="profitChartItem__lbl">перев.</div>
            <div className="profitChartItem__val">{priceFlipStr}</div>
          </div>
          <div className="profitChartItem profitChartItemMiddle">
            <div className="profitChartItem__lbl">вход</div>
            <div className="profitChartItem__val">{positionPriceStr}</div>
          </div>
          <div className="profitChartItem profitChartItemEnd" style={{ right: `${padding}px` }}>
            <div className="profitChartItem__lbl">профит</div>
            <div className="profitChartItem__val">{priceProfitStr}</div>
          </div>
          <div className={`profitChartCurrent ${color}`} style={{ transform: `translateX(${x}px)` }}>
            <div className="profitChartCurrent__lbl">тек.</div>
            <div className="profitChartCurrent__val">{lastPriceStr}</div>
          </div>
          <div className={`profitChartCurrentFill ${color}`} style={{ width: `${fillWidth}px`, transform: `translateX(${fillTranslateX})` }} />
        </>
        :
        <>
          <div className="profitChartItem profitChartItemStart" style={{ left: `${padding}px` }}>
            <div className="profitChartItem__lbl">профит</div>
            <div className="profitChartItem__val">{priceProfitStr}</div>
          </div>
          <div className="profitChartItem profitChartItemMiddle">
            <div className="profitChartItem__lbl">вход</div>
            <div className="profitChartItem__val">{positionPriceStr}</div>
          </div>
          <div className="profitChartItem profitChartItemEnd" style={{ right: `${padding}px` }}>
            <div className="profitChartItem__lbl">перев.</div>
            <div className="profitChartItem__val">{priceFlipStr}</div>
          </div>
          <div className={`profitChartCurrent ${color}`} style={{ transform: `translateX(${x}px)` }}>
            <div className="profitChartCurrent__lbl">тек.</div>
            <div className="profitChartCurrent__val">{lastPriceStr}</div>
          </div>
          <div className={`profitChartCurrentFill ${color}`} style={{ width: `${fillWidth}px`, transform: `translateX(${fillTranslateX})` }} />
        </>
      }
    </div>
  );
};


const RobotProfitStatus = ({ robot, isMobile = false }) => {
  const width = isMobile ? 100 : 160;
  const padding = isMobile ? 8 : 32;

  return (
    <div className="robotProfitStatus" style={{ width: `${width + 20}px` }}>
      <RobotProfitChart robot={robot} width={width} padding={padding} />

      <div className="robotMinMax">
        <div className="robotMinMaxItem">
          <span className="robotMinMaxItem__lbl">Min:</span>
          <span className="robotMinMaxItem__val">{simpleNumber(robot.minPrice)}</span>
        </div>
        <div className="robotMinMaxItem">
          <span className="robotMinMaxItem__lbl">Max:</span>
          <span className="robotMinMaxItem__val">{simpleNumber(robot.maxPrice)}</span>
        </div>
      </div>
    </div>
  );
};

export default RobotProfitStatus;
