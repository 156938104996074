import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  list: [],
  allCount: null,

  active: null,

  detailForEdit: null,
  detail: null,
};

export const Broker = createSlice({
  initialState,
  name: 'Broker',
  reducers: {
    setActiveBroker: (state, action) => {
      state.active = state.list[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getBrokerList.matchFulfilled, (state, action) => {
        state.list = action.payload.results;
        state.allCount = action.payload.count;
        if (!state.active) state.active = state.list[0];
      })
      .addMatcher(api.endpoints.getBrokerDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })
      .addMatcher(api.endpoints.getBrokerForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.getBrokerForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })
      .addMatcher(api.endpoints.updateBroker.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export const { setActiveBroker } = Broker.actions
export default Broker.reducer;
