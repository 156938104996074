import { api } from 'redux/api/api'


const BrokerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBrokerList: builder.query({
      query: (params = null) => {
        return {
          url: 'broker/broker/',
          params: params,
        }
      }
    }),
    getBrokerSimpleList: builder.query({
      query: (params = null) => ({
        url: 'broker/broker/',
        params: params,
      }),
    }),
    getBrokerDetail: builder.query({
      query: (code) => ({
        url: `broker/broker/${code}/`,
      }),
    }),
    getBrokerForEdit: builder.query({
      query: (code) => ({
        url: `broker/broker/${code}/`,
      }),
    }),

    updateBroker: builder.mutation({
      query(values) {
        const url = values.code ? `broker/broker/${values.code}/`: 'broker/broker/';
        const method = values.code ? `PUT`: 'POST';
        return {
          url: url,
          method: method,
          body: values,
        };
      },
    }),
    deleteBroker: builder.mutation({
      query(code) {
        return {
          url: `broker/broker/${code}/`,
          method: 'DELETE',
        };
      },
    }),
    batchDeleteBroker: builder.mutation({
      query(selected) {
        return {
          url: 'broker/broker/delete/',
          method: 'POST',
          body: { selected },
        };
      },
    }),
  }),
});

export default BrokerApi;

const {
  useLazyGetBrokerListQuery,
  useLazyGetBrokerSimpleListQuery,
  useLazyGetBrokerDetailQuery,
  useLazyGetBrokerForEditQuery,
  useUpdateBrokerMutation,
  useDeleteBrokerMutation,
  useBatchDeleteBrokerMutation,
} = BrokerApi;

export {
  useLazyGetBrokerListQuery as useLazyGetListQuery,
  useLazyGetBrokerSimpleListQuery as useLazyGetSimpleListQuery,
  useLazyGetBrokerDetailQuery as useLazyGetDetailQuery,
  useLazyGetBrokerForEditQuery as useLazyGetForEditQuery,
  useUpdateBrokerMutation as useUpdateMutation,
  useDeleteBrokerMutation as useDeleteMutation,
  useBatchDeleteBrokerMutation as useBatchDeleteMutation,
};
