import React from 'react'
import { Form, Checkbox } from 'antd'


const FilterItemCheckboxGroup = ({ label, field, options }) => {
  return (
    <Form.Item name={field} label={label}>
      <Checkbox.Group options={options} />
    </Form.Item>
  );
}

FilterItemCheckboxGroup.getValue = (values) => {
  return values;
}

FilterItemCheckboxGroup.getDisplayValue = (value, config) => {
  const result = config.options.find(opt => {
    return opt.value === value
  })
  return result ? result.label: value;
}

export default FilterItemCheckboxGroup;
