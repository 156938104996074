import { numberWithCommas } from 'utils/format'
import { useInstrumentLastPriceWS } from 'models/Instrument/useHooks'
import { LastPrice } from 'models/Instrument/components'
import Fieldset from 'components/Fieldset'
import Descriptions from 'components/Descriptions'
import DateTimeValue from 'components/DateTimeValue'
import RobotInstrumentValue from '../RobotInstrumentValue'
import RobotInstrumentStatus from '../RobotInstrumentStatus'


const RobotInstrumentDetail = ({ detail }) => {
  const instrument = detail?.instrument;

  const { instrumentLastPrice } = useInstrumentLastPriceWS([instrument?.ticker]);
  const lastPrice = instrumentLastPrice || instrument?.lastPrice;

  return (
    <>
      <Fieldset>
        <Descriptions size="sm">
          <Descriptions.Item label="Название" xs={12} md={6}>
            <RobotInstrumentValue detail={detail} asLink={false} isBack={true} />
          </Descriptions.Item>

          <Descriptions.Item label="Предыдущий" xs={12} md={6}>
            <RobotInstrumentValue detail={detail?.before} asLink={true} isBack={true} />
          </Descriptions.Item>

          <Descriptions.Item label="Статус" xs={12} md={6}>
            <RobotInstrumentStatus status={detail?.changeStatus} />
          </Descriptions.Item>

          <Descriptions.Item label="Посл. сделка" xs={12} md={6}>
            <LastPrice lastPrice={lastPrice} currency={instrument?.currency} />
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Fieldset title="Основное">
        <Descriptions size="sm">
          <Descriptions.Item label="Тип" xs={7} md={8}>{instrument?.instrumentType}</Descriptions.Item>

          <Descriptions.Item label="Дата выпуска" xs={8} md={8}>
            <DateTimeValue date={instrument?.placementDate} format="DD.MM.YYYY" />
          </Descriptions.Item>

          {instrument?.instrumentType === 'future' &&
            <>
              <Descriptions.Item label="Дата погашения" xs={9} md={8}>
                <DateTimeValue date={instrument?.expirationDate} format="DD.MM.YYYY" />
              </Descriptions.Item>

              <Descriptions.Item label="Шаг цены" xs={12} md={4}>{numberWithCommas(instrument?.futureMargin?.minPriceIncrement)}</Descriptions.Item>
              <Descriptions.Item label="Стоим. шага" xs={12} md={4}>{numberWithCommas(instrument?.futureMargin?.minPriceIncrementAmount)}</Descriptions.Item>

              <Descriptions.Item label="Гарант. обесп. при покупке." span={12} md={8}>{numberWithCommas(instrument?.futureMargin?.initialMarginOnBuy)}</Descriptions.Item>
              <Descriptions.Item label="Гарант. обесп. при продаже." span={12} md={8}>{numberWithCommas(instrument?.futureMargin?.initialMarginOnSell)}</Descriptions.Item>
            </>
          }

          <Descriptions.Item label="Размер лота" xs={12} md={8}>{instrument?.lot}</Descriptions.Item>
          <Descriptions.Item label="Валюта" xs={12} md={8}>{instrument?.currency}</Descriptions.Item>
        </Descriptions>
      </Fieldset>

      {instrument?.instrumentType === 'share' &&
        <Fieldset title="Акция">
          <Descriptions size="sm">
            <Descriptions.Item label="Номинал" span={4}>{numberWithCommas(instrument?.nominal)}</Descriptions.Item>
          </Descriptions>
        </Fieldset>}
    </>
  );
}

export default RobotInstrumentDetail
