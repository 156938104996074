import { Page } from 'layout'
import { ExchangeList } from 'models/Exchange/components'

import './styles.css'


const ExchangePage = () => {
  return (
    <Page title="Рынки">
      <ExchangeList />
    </Page>
  );
};

export default ExchangePage
