import React from 'react';
import { Form, InputNumber } from 'antd'

import "./styles.css"


const NumberField = ({ name, label, required, ...inputProps }) => {
  return (
    <Form.Item className="number" label={label} name={name} rules={[{ required: required }]}>
      <InputNumber size="large" {...inputProps} />
    </Form.Item>
  );
};

export default NumberField;
