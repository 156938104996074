import { api } from 'redux/api/api'


const RobotInstrumentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRobotInstrumentList: builder.query({
      query: ({ params = null }) => {
        return {
          url: `robot/instrument/`,
          params: params,
        }
      }
    }),
    getRobotInstrumentSimpleList: builder.query({
      query: ({ params = null }) => ({
        url: `robot/instrument/`,
        params: params,
      }),
    }),
    getRobotInstrumentDetail: builder.query({
      query: ({ ticker }) => ({
        url: `robot/instrument/${ticker}/`,
      }),
    }),

  
    getRobotInstrumentForEdit: builder.query({
      query: ({ ticker }) => ({
        url: `robot/instrument/${ticker}/change/`,
      }),
    }),

    updateRobotInstrumentChange: builder.mutation({
      query(values) {
        console.log('values', values);
        return {
          url: `robot/instrument/${values.old_ticker}/change/`,
          method: 'PUT',
          body: values,
        };
      },
    }),
  }),
});

export default RobotInstrumentApi;
