import { Page } from 'layout'
import { AccountList } from 'models/Account/components'

import './styles.css'


const AccountPage = () => {
  return (
    <Page title="Счета">
      <AccountList />
    </Page>
  );
};

export default AccountPage
