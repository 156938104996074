import React, { useMemo } from 'react'
import { useNavigate } from "react-router-dom"
import { Input, Button } from 'antd'
import { FilterOutlined, SearchOutlined } from '@ant-design/icons'

import { getDataQuery } from 'utils/urls'
import { FilterForm } from 'components/Filter'

import './styles.css'


const TableTop = ({
  title,
  searchParams,
  objectSearchParams,
  queryKey,
  onLoadData,
  topActions,
  showSearch,
  showFilterButton,
  topFilterFields,
  onFilterToggle,
}) => {
  const navigate = useNavigate();

  const searchQuery = useMemo(() => {
    return objectSearchParams.get('search');
  }, [objectSearchParams]);

  const onSearch = (value) => {
    objectSearchParams.set('search', value);

    const [dataQuery, searchString] = getDataQuery(searchParams, objectSearchParams, queryKey);
    navigate({ search: searchString, replace: true });
    onLoadData && onLoadData({ dataQuery });
  };

  const onSearchChange = (e) => {
    onSearch(e.target.value);
  };

  const actions = topActions ? topActions.slice() : [];

  if (!title && !showSearch && !topFilterFields && !showFilterButton && actions.length === 0) {
    return null
  }

  return (
    <div className="tableTop">
      <div className="tableTop__start">
        {title && <div className="tableTopTitle">{title}</div>}
        {showSearch && (
          <Input
            className="tableTopSearch"
            placeholder="Поиск"
            value={searchQuery}
            allowClear={true}
            onChange={onSearchChange}
            prefix={<SearchOutlined />}
            size="large"
          />
        )}
      </div>

      <div className="tableTop__end">
        {topFilterFields && (
          <FilterForm
            layout="horizontal"
            onLoadData={onLoadData}
            searchParams={searchParams}
            objectSearchParams={objectSearchParams}
            queryKey={queryKey}
            filterFields={topFilterFields}
          />
        )}

        {showFilterButton && (
          <Button className="filterBtn" onClick={onFilterToggle} size="large">
            <FilterOutlined /><span className="filterBtnTitle">Фильтры</span>
          </Button>
        )}
      </div>

      {actions.length > 0 && (
        <div className="tableTop__end">
          {actions.map((item, index) => (
            <div className="action" key={`action${index}`}>
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableTop;
