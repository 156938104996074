import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  list: [],
  allCount: null,

  detailForEdit: null,
  detail: null,
};

export const Account = createSlice({
  initialState,
  name: 'Account',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getAccountList.matchPending, (state, action) => {
        state.list = [];
        state.allCount = 0;
      })
      .addMatcher(api.endpoints.getAccountList.matchFulfilled, (state, action) => {
        state.list = action.payload.results;
        state.allCount = action.payload.count;
      })
      .addMatcher(api.endpoints.getAccountDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })
      .addMatcher(api.endpoints.getAccountForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.getAccountForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })
      .addMatcher(api.endpoints.updateAccount.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default Account.reducer;
