import React from 'react'
import { Button } from 'antd'

import ActionButton from 'components/ActionButton'


import './styles.css'


const PageHeaderActions = ({ detail, actions }) => {
  return (
    <div className="pageHeaderActions">
      {actions &&
        actions.map((item, index) => {
          if (item.mode === 'group') {
            return (
              <div className="pageHeaderActionsItem" key={`btn${index}`}>
                <ActionButton
                  detail={detail}
                  actions={item.actions}
                />
              </div>
            );
          }

          const defaultItem = ActionButton.DefaultActionByName[item.name];
          const icon = item.icon || defaultItem?.icon;
          const label = item.label || defaultItem?.label;

          return (
            <div className="pageHeaderActionsItem" key={`btn${index}`}>
              <Button type={item.type || 'primary'} shape={item.shape} className={item.className} onClick={() => item.action(detail)} size="middle">
                {icon}
                {label}
              </Button>
            </div>
          );
        })}
    </div>
  );
}


export default PageHeaderActions;