import UserPanel from 'components/UserPanel'
import Navigation from 'layout/Navigation'
import { BrokerChooser } from 'models/Broker/components'

import "./styles.css"


const AppHeader = () => {
  return (
    <div className="appHeader">
      <div className="appHeaderStart">
        <div className="logo" />

        <BrokerChooser />
      </div>

      <div className="appHeaderMiddle">
        <Navigation />
      </div>

      <div className="appHeaderEnd">
        <UserPanel />
      </div>
    </div>
  );
};

export default AppHeader;
