import { api } from 'redux/api/api'


const RobotGroupApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRobotGroupList: builder.query({
      query: ({ params = null }) => {
        return {
          url: `robot/group/`,
          params: params,
        }
      }
    }),
    getRobotGroupSimpleList: builder.query({
      query: ({ params = null }) => ({
        url: `robot/group/`,
        params: params,
      }),
    }),
    getRobotGroupDetail: builder.query({
      query: ({ id }) => ({
        url: `robot/group/${id}/`,
      }),
    }),
    getRobotGroupForEdit: builder.query({
      query: ({ id }) => ({
        url: `robot/group/${id}/`,
      }),
    }),

    updateRobotGroup: builder.mutation({
      query(values) {
        const url = values.id ? `robot/group/${values.id}/` : `robot/group/`;
        const method = values.id ? `PUT` : 'POST';
        return {
          url: url,
          method: method,
          body: values,
        };
      },
    }),
    deleteRobotGroup: builder.mutation({
      query({ id }) {
        return {
          url: `robot/group/${id}/`,
          method: 'DELETE',
        };
      },
    }),
    batchDeleteRobotGroup: builder.mutation({
      query({ selected }) {
        return {
          url: `robot/group/delete/`,
          method: 'POST',
          body: { selected },
        };
      },
    }),

    getRobotGroupForChangeInstrument: builder.query({
      query: ({ id }) => ({
        url: `robot/group/${id}/changeInstrument/`,
      }),
    }),
    updateRobotGroupChangeInstrument: builder.mutation({
      query(values) {
        return {
          url: `robot/group/${values.id}/changeInstrument/`,
          method: 'PUT',
          body: values,
        };
      },
    }),
  }),
});

export default RobotGroupApi;

const {
  useLazyGetRobotGroupListQuery,
  useLazyGetRobotGroupSimpleListQuery,
  useLazyGetRobotGroupDetailQuery,
  useLazyGetRobotGroupForEditQuery,
  useUpdateRobotGroupMutation,
  useDeleteRobotGroupMutation,
  useBatchDeleteRobotGroupMutation,

  useLazyGetRobotGroupForChangeInstrumentQuery,
  useUpdateRobotGroupChangeInstrumentMutation,
} = RobotGroupApi;

export {
  useLazyGetRobotGroupListQuery as useLazyGetListQuery,
  useLazyGetRobotGroupSimpleListQuery as useLazyGetSimpleListQuery,
  useLazyGetRobotGroupDetailQuery as useLazyGetDetailQuery,
  useLazyGetRobotGroupForEditQuery as useLazyGetForEditQuery,
  useUpdateRobotGroupMutation as useUpdateMutation,
  useDeleteRobotGroupMutation as useDeleteMutation,
  useBatchDeleteRobotGroupMutation as useBatchDeleteMutation,

  useLazyGetRobotGroupForChangeInstrumentQuery,
  useUpdateRobotGroupChangeInstrumentMutation,
};
