import React, { useEffect, useState, useMemo, forwardRef, useImperativeHandle } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { Table } from 'antd'

import { getObjectParams, getDataQuery, combineDataQuery } from 'utils/urls'
import { FilterDrawer, FilterValues } from 'components/Filter'
import TableTop from './TableTop'
import TableRawActions from './TableRawActions'

import './styles.css'


function getOrder(field, orderField) {
  if (orderField === field) return 'ascend';
  if (orderField === `-${field}`) return 'descend';
  return false;
}

function getOrderField(field, order) {
  if (order === 'ascend') return field;
  if (order === 'descend') return `-${field}`;
  return false;
}

function getPagination(objectSearchParams, count, allCount, pageSize = 50) {
  const offset = objectSearchParams.get('offset') || 0;
  return { pageSize: pageSize, total: allCount, current: offset / pageSize + 1 };
}


const TableList = forwardRef(({ title, filterParams, fields, columnList, objectList, allCount, queryKey, ...props }, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const objectSearchParams = useMemo(() => {
    return getObjectParams(location.search, queryKey);
  }, [location.search, queryKey]);

  const orderField = objectSearchParams.get('ordering');

  const columns = useMemo(() => {
    return columnList.reduce((result, fieldName) => {
      let config = fields[fieldName];
      if (config) {
        result.push({ ...config, key: fieldName, dataIndex: fieldName, sortOrder: getOrder(fieldName, orderField) })
      }
      return result
    }, []);
  }, [columnList, fields, orderField]);

  const onLoadData = ({ dataQuery }) => {
    props.getObjectList && props.getObjectList(combineDataQuery(dataQuery, filterParams));
  }

  const onBatchDelete = (selected) => {
    props.batchDelete && props.batchDelete(selected);
    setSelected([]);
  }

  function loadData() {
    const [dataQuery] = getDataQuery(searchParams, objectSearchParams, queryKey);
    onLoadData({ dataQuery });
  }
  useImperativeHandle(ref, () => ({ loadData }));

  const fParams = filterParams ? Object.values(filterParams) : [];

  useEffect(() => {
    const [dataQuery] = getDataQuery(searchParams, objectSearchParams, queryKey);
    onLoadData({ dataQuery });
    // eslint-disable-next-line
  }, [props.batchDeleteProps?.isSuccess].concat(fParams));

  const onChange = (pagination, filters, sorter) => {
    const ordering = getOrderField(sorter.field, sorter.order);
    if (ordering) {
      objectSearchParams.set('ordering', getOrderField(sorter.field, sorter.order));
    } else {
      objectSearchParams.delete('ordering');
    }

    const offset = (pagination.current - 1) * pagination.pageSize;
    if (offset) {
      objectSearchParams.set('offset', offset);
    } else {
      objectSearchParams.delete('offset');
    }

    const [dataQuery, searchString] = getDataQuery(searchParams, objectSearchParams, queryKey);
    navigate({ search: searchString }, { replace: true });
    onLoadData({ dataQuery });
  };

  const [filterVisible, setFilterVisible] = useState(false);

  const onFilterToggle = () => {
    setFilterVisible(!filterVisible);
  };

  const count = objectList?.length || 0;

  const rowActions = (props.rowActions || []).concat([
    {
      title: 'Удалить',
      icon: 'delete',
      type: 'danger',
      action: (selected) => onBatchDelete(selected),
    },
  ]);

  return (
    <>
      <div className="tableList">
        <TableTop
          title={title}
          searchParams={searchParams}
          objectSearchParams={objectSearchParams}
          queryKey={queryKey}
          onLoadData={onLoadData}
          topActions={props.topActions}
          showSearch={props.showSearch}
          showFilterButton={!!props.filterFields}
          topFilterFields={props.topFilterFields}
          onFilterToggle={onFilterToggle}
        />

        <TableRawActions
          rowActions={rowActions}
          selected={selected}
        />

        {props.showFilterValues &&
          <FilterValues
            searchParams={searchParams}
            objectSearchParams={objectSearchParams}
            queryKey={queryKey}
            onLoadData={onLoadData}
            filterFields={props.filterFields || props.topFilterFields}
          />
        }

        <Table
          title={props.title}
          sticky={props.sticky}
          expandable={props.expandable}
          columns={columns}
          dataSource={objectList || []}
          rowKey="id"
          loading={props.getObjectListProps?.isFetching || props.batchDeleteProps?.isFetching}
          pagination={props.hideNav ? false : getPagination(objectSearchParams, count, allCount)}
          onChange={onChange}
          childrenColumnName={props.childrenColumnName}

          rowSelection={props.rowSelection && {
            selectedRowKeys: selected,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelected(selectedRowKeys.slice());
            },
          }}

          rowClassName={props.rowClassName}
        />

        {!props.hideStat && (
          <div className="tableStat">Показано {count} из {allCount}</div>
        )}
      </div>
      {props.filterFields && (
        <FilterDrawer
          visible={filterVisible}
          onToggle={onFilterToggle}
          onLoadData={onLoadData}
          searchParams={searchParams}
          objectSearchParams={objectSearchParams}
          queryKey={queryKey}
          filterFields={props.filterFields}
        />
      )}
    </>
  );
});


export default TableList;
