import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from "react-router-dom"
import { Button } from 'antd'

import RobotInstrumentApi from 'models/RobotInstrument/api'
import { DetailPage } from 'layout'
import { RobotInstrumentChangeForm, RobotInstrumentDetail } from 'models/RobotInstrument/components'
import { RobotGroupForm, RobotGroupList } from 'models/RobotGroup/components'
import { RobotListWithNav } from 'models/Robot/components'


const RobotInstrumentDetailPage = () => {
  const { ticker } = useParams();

  const navigate = useNavigate();

  const detail = useSelector(state => state.RobotInstrument.detail);
  const [getObjectDetail, getObjectDetailProps] = RobotInstrumentApi.useLazyGetRobotInstrumentDetailQuery();

  const ref = useRef(null);
  const [editRobotGroupFormState, setEditRobotGroupFormState] = useState({});

  const onEditRobotGroup = (objectId = null) => {
    setEditRobotGroupFormState({ visible: true, objectId });
  };

  const [changeInstrumentFormState, setChangeInstrumentFormState] = useState({});
  const onChangeInstrumentFormClose = (isUpdated, data) => {
    setChangeInstrumentFormState({});
    if (isUpdated && data?.instrument) {
      navigate(`/robot-instrument/${data?.instrument}/`, { replace: true })
    }
  }

  const onChangeInstrument = () => {
    setChangeInstrumentFormState({ visible: true, objectId: ticker });
  }

  const title = detail?.id ? `${ticker}: ${detail?.instrument.name}` : ticker;

  let actions = [];
  if (detail?.changeStatus === 'active' && detail?.allow_change) {
    actions.push({ mode: 'button', name: 'changeInstrument', label: 'Заменить инструмент', action: onChangeInstrument })
  }

  return (
    <DetailPage
      objectId={ticker}
      detail={detail}
      title={title}
      showBack={true}
      basePath={`/robot-instrument/${ticker}/`}

      getObjectDetail={(ticker) => getObjectDetail({ ticker })}
      getObjectDetailProps={getObjectDetailProps}

      actions={actions}

      render={(editFormState, onFormClose) => (
        <>
          <RobotInstrumentDetail detail={detail} />

          <RobotGroupList
            title="Группы роботов"
            topActions={[
              <Button type="primary" onClick={() => onEditRobotGroup()}>Добавить группу</Button>
            ]}
            onEdit={onEditRobotGroup}
            ref={ref}
            filterParams={{ instrument: ticker }}
          />

          <RobotListWithNav filterParams={{ instrument: ticker }} />

          <RobotGroupForm
            initials={{ instrument: detail?.instrument.ticker }}
            onClose={(isUpdated) => {
              setEditRobotGroupFormState({});
              isUpdated && ref.current && ref.current.loadData();
            }}
            {...editRobotGroupFormState}
          />

          <RobotInstrumentChangeForm
            onClose={onChangeInstrumentFormClose}
            {...changeInstrumentFormState}
          />
        </>
      )}
    />
  );
}

export default RobotInstrumentDetailPage
