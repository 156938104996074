import { useMemo, useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

import { useLazyGetListQuery } from 'models/Instrument/api'
import { useInstrumentLastPriceWS } from 'models/Instrument/useHooks'
import TableList from 'components/TableList'
import InstrumentValue from '../InstrumentValue'
import LastPrice from '../LastPrice'


const defaultColumnList = ['name', 'robotGroups', 'instrumentType', 'basicAsset', 'lastPrice', 'dateSynced'];


const InstrumentList = forwardRef(({ columnList, filterParams, topActions, onEdit }, ref) => {
  const tableRef = useRef(null);

  const objectList = useSelector(state => state.Instrument.list);
  const allCount = useSelector(state => state.Instrument.allCount);
  const [getObjectList, getObjectListProps] = useLazyGetListQuery();

  const tickers = objectList.map(item => item.ticker);
  const { instrumentLastPrice } = useInstrumentLastPriceWS(tickers);

  const objectListWithParams = useMemo(() => {
    if (!instrumentLastPrice) return objectList;
    return objectList.map(item => {
      if (instrumentLastPrice.ticker === item.ticker) {
        return { ...item, lastPrice: instrumentLastPrice };
      }
      return item;
    })
  }, [objectList, instrumentLastPrice]);

  if (!columnList) {
    columnList = defaultColumnList;
  }

  const fields = {
    id: {
      title: 'ID',
      sorter: true,
      width: 80,
    },
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<InstrumentValue detail={item} asLink={true} isBack={true} />),
    },
    ticker: {
      title: 'Тикер',
      sorter: true,
      width: 130,
    },
    robotGroups: {
      title: 'Роботы',
      width: 80,
      render: (val, item) => (
        <>
          <div>{item.robotGroupCount}</div>
        </>
      ),
    },
    instrumentType: {
      title: 'Тип',
      sorter: true,
      width: 100,
      responsive: ["md"],
    },
    basicAsset: {
      title: 'Базовый актив',
      sorter: true,
      width: 230,
      responsive: ["md"],
      render: (val, item) => (<InstrumentValue detail={{ ticker: val }} asLink={false} isBack={true} />),
    },

    lastPrice: {
      title: 'Посл. сделка',
      width: 150,
      render: (val, item) => <LastPrice lastPrice={val} instrument={item} />,
    },
  };

  function loadData() {
    tableRef.current && tableRef.current.loadData();
  }
  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectListWithParams}
      allCount={allCount}
      queryKey="Instrument"

      getObjectList={(params) => getObjectList({ params })}
      getObjectListProps={getObjectListProps}
      onEdit={onEdit}

      topActions={topActions}
      showSearch={true}
      rowSelection={false}
    />
  );
});

export default InstrumentList
