import TableList from 'components/TableList'
import DateTimeValue from 'components/DateTimeValue'

const defaultColumnList = ['date', 'startTime', 'endTime'];


const MarketTradingDayList = ({ columnList, days }) => {
  const objectList = days;

  if (!columnList) {
    columnList = defaultColumnList;
  }

  const fields = {
    id: {
      title: 'ID',
      sorter: true,
      width: 80,
    },
    date: {
      title: 'Дата',
      render: (val, item) => (<DateTimeValue size="14" date={val} format="DD.MM.YYYY" />),
    },
    startTime: {
      title: 'Начало',
      width: 130,
      render: (val, item) => (item.isTradingDay ? <DateTimeValue size="14" date={val} format="HH:mm" />: '-'),
    },
    endTime: {
      title: 'Окончание',
      width: 130,
      render: (val, item) => (item.isTradingDay ? <DateTimeValue size="14" date={val} format="HH:mm" />: '-'),
    },
  };

  return (
    <TableList
      fields={fields}
      columnList={columnList}
      objectList={objectList}
      allCount={objectList.length}

      showSearch={false}
      rowSelection={false}
    />
  );
}

export default MarketTradingDayList;
