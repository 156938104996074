import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { api } from './api/api'
import appSlice from './features/appSlice'
import authSlice from './features/authSlice'

import BrokerReducer from 'models/Broker/BrokerReducer'
import AccountReducer from 'models/Account/AccountReducer'
import ExchangeReducer from 'models/Exchange/ExchangeReducer'
import InstrumentReducer from 'models/Instrument/InstrumentReducer'
import RobotInstrumentReducer from 'models/RobotInstrument/RobotInstrumentReducer'
import RobotGroupReducer from 'models/RobotGroup/RobotGroupReducer'
import RobotReducer from 'models/Robot/RobotReducer'
import RobotLogReducer from 'models/RobotLog/RobotLogReducer'


const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  app: appSlice,
  auth: authSlice,

  Broker: BrokerReducer,
  Account: AccountReducer,
  Exchange: ExchangeReducer,
  Instrument: InstrumentReducer,
  RobotInstrument: RobotInstrumentReducer,
  RobotGroup: RobotGroupReducer,
  Robot: RobotReducer,
  RobotLog: RobotLogReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'Broker']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [
    thunk,
    api.middleware, 
  ]
})

export const persistor = persistStore(store)
