import { numberWithCommas } from 'utils/format'
import { useInstrumentLastPriceWS } from 'models/Instrument/useHooks'
import Fieldset from 'components/Fieldset'
import Descriptions from 'components/Descriptions'
import DateTimeValue from 'components/DateTimeValue'
import InstrumentValue from '../InstrumentValue'
import LastPrice from '../LastPrice'


const InstrumentDetail = ({ detail }) => {
  const { instrumentLastPrice } = useInstrumentLastPriceWS([detail?.ticker]);
  const lastPrice = instrumentLastPrice || detail?.lastPrice;

  return (
    <>
      <Fieldset>
        <Descriptions size="sm">
          <Descriptions.Item label="Название" flex="auto">
            <InstrumentValue detail={detail} asLink={false} isBack={true} />
          </Descriptions.Item>

          <Descriptions.Item label="Посл. сделка" flex="100px">
            <LastPrice lastPrice={lastPrice} currency={detail?.currency} />
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Fieldset title="Основное">
        <Descriptions size="sm">
          <Descriptions.Item label="Тип" xs={7} md={8}>{detail?.instrumentType}</Descriptions.Item>

          <Descriptions.Item label="Дата выпуска" xs={8} md={8}>
            <DateTimeValue date={detail?.placementDate} format="DD.MM.YYYY" />
          </Descriptions.Item>

          {detail?.instrumentType === 'future' &&
            <>
              <Descriptions.Item label="Дата погашения" xs={9} md={8}>
                <DateTimeValue date={detail?.expirationDate} format="DD.MM.YYYY" />
              </Descriptions.Item>

              <Descriptions.Item label="Шаг цены" xs={12} md={4}>{numberWithCommas(detail?.futureMargin?.minPriceIncrement)}</Descriptions.Item>
              <Descriptions.Item label="Стоим. шага" xs={12} md={4}>{numberWithCommas(detail?.futureMargin?.minPriceIncrementAmount)}</Descriptions.Item>

              <Descriptions.Item label="Гарант. обесп. при покупке." span={12} md={8}>{numberWithCommas(detail?.futureMargin?.initialMarginOnBuy)}</Descriptions.Item>
              <Descriptions.Item label="Гарант. обесп. при продаже." span={12} md={8}>{numberWithCommas(detail?.futureMargin?.initialMarginOnSell)}</Descriptions.Item>
            </>
          }

          <Descriptions.Item label="Размер лота" xs={12} md={8}>{detail?.lot}</Descriptions.Item>
          <Descriptions.Item label="Валюта" xs={12} md={8}>{detail?.currency}</Descriptions.Item>
        </Descriptions>
      </Fieldset>

      {detail?.instrumentType === 'share' &&
        <Fieldset title="Акция">
          <Descriptions size="sm">
            <Descriptions.Item label="Номинал" span={4}>{numberWithCommas(detail?.nominal)}</Descriptions.Item>
          </Descriptions>
        </Fieldset>}
    </>
  );
}

export default InstrumentDetail
