import './styles.css'


const RobotPosition = ({ robot, style, showAmount = true }) => {
  if (!robot || robot.direction === 0) return null;

  const positionPrice = robot.price;
  const positionKoef = robot.quantity;
  const amount = robot.quantity * robot.price;

  return (
    <div className="robotPosition" style={style}>
      <div className={`robotPositionTag dir${robot?.direction}`}>
        <span>{positionKoef}</span>x<span>{robot.direction === 1 ? 'long': 'short'}</span>
      </div>

      {showAmount && <div className="robotPositionAmount">{positionKoef}x{positionPrice}={amount}</div>}
    </div>
  );
};

export default RobotPosition;
