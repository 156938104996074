import React, { useMemo } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { Tabs } from 'antd'

import "./styles.css"


const PageHeaderTabs = ({ basePath, tabs, onTabChange }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const activeTab = useMemo(() => {
    const path = location.pathname.replace(basePath, '');
    let i = 0, n = tabs.length;
    while (i < n) {
      const tab = tabs[i];
      const key = tab.key.replace(basePath, '');
      if (path === key && key === "") return tab.key;
      if (key && path.startsWith(key.replace(basePath, ''))) {
        return tab.key;
      }
      i++;
    }
  }, [location.pathname, basePath, tabs]);

  const onChange = (key) => {
    if (onTabChange) {
      onTabChange(key);
    } else {
      const url = `${basePath}${key}`;
      navigate(url, { replace: true });
    }
  };

  return (
    <Tabs
      className="pageHeaderTabs"
      tabPosition="top"
      activeKey={activeTab}
      items={tabs}
      onChange={onChange}
      animated={false}
    />
  );
};


export default PageHeaderTabs;
