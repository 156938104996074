import { useLocation, useNavigate } from "react-router-dom"
import { Menu } from 'antd'
import { RobotOutlined, FundViewOutlined, GlobalOutlined } from "@ant-design/icons"

import './styles.css';


function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem('Роботы', '/robot/', <RobotOutlined />),
  //getItem('Группы роботов', '/robotGroup/', <AppstoreOutlined />),

  getItem('Инструменты', '/robot-instrument/', <FundViewOutlined />),
  getItem('Рынки', '/exchange/', <GlobalOutlined />),
  getItem('Счета', '/account/', <GlobalOutlined />),
];


const Navigation = ({ isMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const selectedKeys = location.pathname.slice(1, -1).split('/').reduce((result, slug) => {
    const before = result.length > 0 ? result[result.length - 1] : '/';
    result.push(`${before}${slug}/`);
    return result
  }, []);

  const onNavigate = ({ key }) => {
    navigate(key);
  }

  let cls = "navigation";
  if (isMobile) cls = "navigationMobile";

  return (
    <Menu
      className={cls}
      mode="horizontal"
      inlineIndent={16}
      //mode="inline"
      multiple={false}
      theme="dark"
      items={items}
      defaultOpenKeys={selectedKeys}
      //defaultSelectedKeys={[currentPath, '/tenants/tenant/']}
      selectedKeys={selectedKeys}
      onClick={onNavigate}
    />
  );
}

export default Navigation;
