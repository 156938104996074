import { useState, useEffect } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import { WS_URL } from 'config'


export function useInstrumentLastPriceWS(tickers) {
  const [instrumentLastPrice, setInstrumentLastPrice] = useState(null);

  const { readyState, sendMessage } = useWebSocket(`${WS_URL}instrument/`, {
    onMessage: (event) => {
      const msg = JSON.parse(event.data);
      if (msg?.type === 'instrumentLastPrice') {
        const lastPrice = JSON.parse(msg.lastPrice);
        if (tickers.includes(lastPrice.ticker)) {
          setInstrumentLastPrice(lastPrice);
        }
      }
    },
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const message = {
        type: 'subscribeInstrumentLastPrices',
        tickers: tickers,
      }
      sendMessage(JSON.stringify(message));
    }
  }, [readyState, sendMessage, tickers]);

  return { instrumentLastPrice };
}
