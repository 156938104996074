import React from 'react'

import DetailValue from 'components/DetailValue'


const ExchangeValue = ({ detail, asLink, isBack }) => {
  return (
    <>
      <DetailValue 
        linkUrl={asLink && `/exchange/${detail.code}/`} 
        isBack={isBack} 
        render={() => (
          <div className="valueFlex">
            {detail?.name || detail.code}
          </div>
        )}
      />
    </>
  );
};


export default ExchangeValue
