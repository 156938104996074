import React from 'react';
import { Form, Select } from 'antd'

import "./styles.css"


const SelectField = ({ name, label, required, options, disabled }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <Select size="large" options={options} disabled={disabled} />
    </Form.Item>
  );
};

export default SelectField;
