import React from 'react'
import { Select } from 'antd'

import { useLazyGetSimpleListQuery } from 'models/Account/api'
import ListLoadComponent from 'components/ListLoadComponent'


const AccountSelect = ({ filter, disabled, required, ...props }) => {
  const [getObjectList, getObjectListProps] = useLazyGetSimpleListQuery();

  return (
    <ListLoadComponent
      getObjectList={getObjectList}
      getObjectListProps={getObjectListProps}
      filter={filter}

      render={({ items }) => {
        return (
          <Select
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Выберите"
            showSearch={true}
            allowClear={true}
            disabled={disabled}
            optionFilterProp="label"
            size="large"
            onChange={props.onChange}
            {...props}
          >
            {!required && <Select.Option value={''}>---</Select.Option>}
            {items.map(item => {
              return (
                <Select.Option key={`category${item.id}`} value={item.id} label={item.name}>
                  {item.name}
                </Select.Option>
              )
            })}
          </Select>
        );
      }}
    />
  );
}

export default AccountSelect
