import { useState, useEffect } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import moment from 'moment-timezone'

import { WS_URL } from 'config'


export function useBrokerWS() {
  const [brokerStatus, setBrokerStatus] = useState(null);

  const { readyState, sendMessage } = useWebSocket(`${WS_URL}broker/`, {
    onMessage: (event) => {
      const msg = JSON.parse(event.data);
      if (msg?.type === 'brokerStatus') {
        let status = JSON.parse(msg.status);
        status.time = moment(status?.time).tz('Europe/Moscow');
        setBrokerStatus(status);
      }
    },
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const message = {
        type: 'getStatus',
      }
      sendMessage(JSON.stringify(message));
    } else if (readyState === ReadyState.CLOSED) {
      setBrokerStatus((prev) => ({ ...prev, isConnected: false }));
    }
  }, [readyState, sendMessage]);

  return { brokerStatus };
}
