import { useState, useEffect, useMemo } from 'react'
import { Spin } from 'antd'
import { StarFilled, StarOutlined } from '@ant-design/icons'

import './styles.css'


const Favorite = ({ detail, fieldName, itemUpdate, itemUpdateProps, pkField = 'id', addData }) => {
  const [loading, setLoading] = useState(false);

  const isFavorite = detail && detail[fieldName];

  const onChange = data => {
    if (!loading) {
      setLoading(true);
      if (addData) {
        data = { ...data, ...addData }
      }
      itemUpdate(data);
    }
  }

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [itemUpdateProps.isSuccess]);

  const icon = useMemo(() => {
    return isFavorite ? <StarFilled /> : <StarOutlined />;
  }, [isFavorite]);

  return (
    <div
      className={`favorite ${isFavorite ? 'active' : ''}`}
      onClick={() => {
        onChange({ [pkField]: detail[pkField], [fieldName]: !detail[fieldName] });
      }}
    >
      {loading ? <Spin /> : icon}
    </div>
  );
};

export default Favorite;
