import { useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

//import { useRobotWS } from 'models/Robot/useHooks'
import { useLazyGetListQuery, useUpdateMutation } from 'models/Robot/api'
import TableList from 'components/TableList'
import { FilterItemRadioButtons } from 'components/Filter'
import DateTimeValue from 'components/DateTimeValue'
import Favorite from 'components/Favorite'
import PropertyList from 'components/PropertyList'
import RobotValue from '../RobotValue'
import RobotStatus from '../RobotStatus'
import RobotPosition from '../RobotPosition'
import RobotCurrentProfit from '../RobotCurrentProfit'
import RobotProfitStatus from '../RobotProfitStatus'


const defaultColumnList = [
  'instrument', 'mobileStatus', 'mobileInfo', 'dateStarted', 'status', 'profitStatus', 'currentProfit',
];


const RobotList = forwardRef(({ title, columnList, topActions, onEdit, showSearch, showFilter, filterParams }, ref) => {
  const tableRef = useRef(null);

  const activeBroker = useSelector(state => state.Broker.active);
  const objectList = useSelector(state => state.Robot.list);
  const objectListInfo = useSelector(state => state.Robot.listInfo);
  const allCount = useSelector(state => state.Robot.allCount);
  const [getObjectList, getObjectListProps] = useLazyGetListQuery();
  const [itemUpdate, itemUpdateProps] = useUpdateMutation();

  //const robotIds =  objectList.map(item => item.id);
  //const { changedRobot } = useRobotWS(robotIds);

  let robotList = objectList;
  //console.log('changedRobot', changedRobot);

  /*
  const robotList = useMemo(() => {
    if (!changedRobot) return objectList;
    return objectList.map(item => {
      if (changedRobot.id === item.id) {
        return { ...changedRobot };
      }
      return item;
    })
  }, [objectList, changedRobot]);*/

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      loadData();
    }
    // eslint-disable-next-line
  }, [itemUpdateProps.isSuccess]);

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      loadData();
    }
    // eslint-disable-next-line
  }, [itemUpdateProps.isSuccess]);

  const fields = {
    id: {
      title: 'ID',
      sorter: true,
      width: 80,
    },
    isFavorite: {
      title: '',
      sorter: false,
      width: 30,
      responsive: ["md"],
      render: (val, item) => (
        <Favorite
          detail={item}
          fieldName="isFavorite"
          addData={{ broker: activeBroker?.id }}
          itemUpdate={itemUpdate}
          itemUpdateProps={itemUpdateProps}
        />
      ),
    },
    instrument: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<RobotValue detail={item} asLink={true} isBack={true} />),
    },
    mobileStatus: {
      title: 'Статус',
      sorter: false,
      width: 120,
      responsive: ["xs"],
      render: (val, item) => (
        <div>
          <RobotStatus status={item.status} changeStatus={item.changeStatus} style={{ marginBottom: '8px' }} />
          <RobotPosition robot={item} showAmount={false} style={{ marginBottom: '4px' }} />
          <RobotCurrentProfit robot={item} />
        </div>
      ),
    },
    mobileInfo: {
      title: 'Информация',
      sorter: false,
      responsive: ["xs"],
      render: (val, item) => <RobotProfitStatus robot={item} isMobile={true} />,
    },
    dateStarted: {
      title: 'Дата',
      sorter: true,
      width: 160,
      responsive: ["md"],
      render: (val, item) => (
        <PropertyList
          mode="compact"
          items={[
            { label: 'Начало', value: <DateTimeValue size="12" date={item.dateStarted} format="DD.MM.YYYY HH:mm" /> },
            { label: 'Завершение', value: <DateTimeValue size="12" date={item.dateEnded} format="DD.MM.YYYY HH:mm" /> },
          ]}
        />
      ),
    },
    status: {
      title: 'Статус',
      width: 150,
      responsive: ["md"],
      render: (val, item) => (
        <>
          <RobotStatus status={item.status} changeStatus={item.changeStatus} style={{ marginBottom: '4px' }} />
          <RobotPosition robot={item} showAmount={true} />
        </>
      ),
    },
    profitStatus: {
      title: 'информация',
      width: 224,
      responsive: ["md"],
      render: (val, item) => {
        return <RobotProfitStatus robot={item} />
      },
    },

    currentProfit: {
      title: 'профит/убыток',
      width: 160,
      responsive: ["md"],
      render: (val, item) => <RobotCurrentProfit robot={item} />,
    },
  };

  function loadData() {
    tableRef.current && tableRef.current.loadData();
  }
  useImperativeHandle(ref, () => ({ loadData }));


  const flipCountsFilterOptions = (objectListInfo?.flipCounts || []).map(item => (
    {
      label: <>{item.flipCount}:<i>{item.count}</i></>,
      value: `${item.flipCount}`
    }
  ))

  return (
    <TableList
      ref={tableRef}
      title={title}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={robotList}
      allCount={allCount}
      queryKey="Robot"

      getObjectList={(params) => getObjectList({ params })}
      getObjectListProps={getObjectListProps}
      onEdit={onEdit}

      topActions={topActions}
      showSearch={showSearch}
      rowSelection={false}

      topFilterFields={[
        {
          label: 'Перевороты',
          field: 'flipCount',
          component: FilterItemRadioButtons,
          options: flipCountsFilterOptions,
          size: 'small'
        },
      ]}
    />
  );
});

export default RobotList;
