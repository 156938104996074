import React, { useMemo } from 'react'
import { Dropdown, Popconfirm } from 'antd'
import classNames from 'classnames'
import { MoreOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"

import './styles.css'


const DefaultActionByName = {
  add: {
    label: 'Добавить',
    icon: <PlusOutlined />,
  },
  edit: {
    label: 'Редактировать',
    icon: <EditOutlined />,
  },
  delete: {
    label: 'Удалить',
    icon: <DeleteOutlined />,
    confirm: {
      title: 'Уверены, что хотите удалить?',
      okText: 'Удалить',
      cancelText: 'Отмена'
    }
  },
}

const ActionButton = ({ className, actions, detail }) => {
  const cx = classNames([
    'actionButton',
    className
  ]);

  const menuItems = useMemo(() => {
    return actions.map(item => {
      const defaultItem = DefaultActionByName[item.name];

      const confirm = item.confirm || defaultItem.confirm;
      const icon = item.icon || defaultItem.icon;
      let label = item.label || defaultItem.label;
      if (confirm) {
        label = (
          <Popconfirm
            placement="bottomRight"
            title={confirm.title}
            okText={confirm.okText}
            cancelText={confirm.cancelText}
            onConfirm={() => item.action(detail)}
            onClick={e => e.stopPropagation()}
          //getPopupContainer={trigger => trigger.parentNode}
          >
            {label}
          </Popconfirm>
        )
      } else {
        label = (
          <div onClick={e => { e.stopPropagation(); item.action(detail); }}>
            {label}
          </div>
        )
      }

      return { key: item.name, icon, label }
    })
  }, [actions, detail]);

  return (
    <Dropdown className={cx} menu={{ items: menuItems }} trigger = { ['click']} >
      <MoreOutlined />
    </Dropdown >
  );
};

ActionButton.DefaultActionByName = DefaultActionByName;

export default ActionButton;
