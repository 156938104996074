import { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom"
import { Button } from 'antd'

import { Page } from 'layout'
import { RobotInstrumentList, RobotInstrumentChangeForm } from 'models/RobotInstrument/components'
import { RobotGroupForm } from 'models/RobotGroup/components'

import './styles.css'


const RobotPage = () => {
  const ref = useRef(null);
  const navigate = useNavigate();

  const [editFormState, setEditFormState] = useState({});
  const onEdit = (objectId = null) => {
    setEditFormState({ visible: true, objectId });
  };


  const [changeInstrumentFormState, setChangeInstrumentFormState] = useState({});
  const onChangeInstrumentFormClose = (isUpdated, data) => {
    setChangeInstrumentFormState({});
    if (isUpdated && data?.instrument) {
      navigate(`/robot-instrument/${data?.instrument}/`, { replace: true })
    }
  }

  const onChangeInstrument = (item) => {
    setChangeInstrumentFormState({ visible: true, objectId: item.instrument.ticker });
  }

  return (
    <Page
      title="Инструменты в работе"
      basePath="/robot-instrument/"
    >
      <RobotInstrumentList
        ref={ref}
        showSearch={true}
        onEdit={onEdit}
        topActions={[
          <Button type="primary" onClick={() => onEdit()}>Добавить</Button>
        ]}

        onChangeInstrument={onChangeInstrument}
      />

      <RobotGroupForm
        onClose={(isUpdated) => {
          setEditFormState({});
          isUpdated && ref.current && ref.current.loadData();
        }}
        {...editFormState}
      />

      <RobotInstrumentChangeForm
        onClose={onChangeInstrumentFormClose}
        {...changeInstrumentFormState}
      />
    </Page>
  );
};

export default RobotPage
