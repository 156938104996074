import React, { useMemo } from 'react'
import { useNavigate } from "react-router-dom"
import { Tag } from 'antd'

import { getDataQuery } from 'utils/urls'

import './styles.css'


const excludeFields = ['ordering', 'offset', 'search'];


const FilterValueItem = ({ filterValueItem, onRemoveFilterParam }) => {
  return (
    <div className="filterValue">
      <div className="filterValue__label">{filterValueItem.label}:</div>
      <div className="filterValue__values">
        {filterValueItem.values.map(([value, label]) => (
          <Tag
            key={`fv-${filterValueItem.name}-${value}`}
            closable
            onClose={e => onRemoveFilterParam(e, filterValueItem.name, value)}
          >
            {label}
          </Tag>
        ))}
      </div>
    </div>
  )
}


const FilterValues = ({ searchParams, objectSearchParams, queryKey, onLoadData, filterFields }) => {
  const navigate = useNavigate();

  const filterFieldsByName = useMemo(() => {
    return (filterFields || []).reduce((res, item) => {
      res[item.field] = item;
      return res;
    }, {})
  }, [filterFields]);

  const filters = useMemo(() => {
    let fieldNames = {};
    let result = [];
    for (let fieldName of objectSearchParams.keys()) {
      if (excludeFields.includes(fieldName) || !objectSearchParams.get(fieldName)) {
        continue
      }
      const cleanFieldName = fieldName.split('__')[0];
      const config = filterFieldsByName[cleanFieldName] || {};
      if (!config) continue;
      const values = objectSearchParams.getAll(fieldName);

      if (!fieldNames[cleanFieldName]) {
        fieldNames[cleanFieldName] = 1;
        result.push({
          name: cleanFieldName,
          label: config.label || cleanFieldName,
          values: values.map(v => ([v, config.component && config.component.getDisplayValue(v, config)])),
        });
      }
    }
    return result;
  }, [filterFieldsByName, objectSearchParams]);

  const onRemoveFilterParam = (e, fieldName, valueToRemove) => {
    e.preventDefault();

    const values = objectSearchParams.getAll(fieldName);
    objectSearchParams.delete(fieldName);
    for (const value of values) {
      if (value !== valueToRemove) {
        objectSearchParams.append(fieldName, value);
      }
    }

    const [dataQuery, searchString] = getDataQuery(searchParams, objectSearchParams, queryKey);
    navigate({ search: searchString, replace: true });
    onLoadData && onLoadData({ dataQuery });
  };

  if (filters.length === 0) return null;

  return (
    <div className="filterValues">
      {filters.map(filterValueItem => (
        <FilterValueItem key={`${filterValueItem.name}`} filterValueItem={filterValueItem} onRemoveFilterParam={onRemoveFilterParam} />
      ))}
    </div>
  );
};

export default FilterValues;
