import React from 'react'
import { useSelector } from 'react-redux'
import { Dropdown } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'

import { useLogoutUserMutation } from 'redux/api/authApi'
import { getFullNameOrEmail } from 'utils/helpers'
import Avatar from 'components/Avatar'

import './styles.css'



const UserPanel = () => {
  const user = useSelector(state => state.auth.user);
  const [logoutUser] = useLogoutUserMutation();

  const menuItems = [
    {
      key: 'logout',
      icon: <LogoutOutlined/>,
      label: (
        <span onClick={() => logoutUser()}>
          Выход
        </span>
      ),
    },
  ];

  const name = getFullNameOrEmail(user);

  return (
    <Dropdown menu={{ items: menuItems }} overlayClassName="userPanelDropdown">
      <div className="userPanel">
        <Avatar className="userPanel__avatar" image={user?.photo} size={32} />
        <span className="userPanel__name">{name}</span>
      </div>
    </Dropdown>
  );
};

export default UserPanel;
