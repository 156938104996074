import { Tag } from 'antd'
import DetailValue from 'components/DetailValue'

import './styles.css'


const InstrumentValue = ({ detail, asLink, isBack }) => {
  return (
    <>
      <DetailValue
        className="instrumentValue"
        linkUrl={asLink && `/instrument/${detail?.ticker}/`}
        isBack={isBack}
        render={() => (
          <div className="instrumentValue">
            <div className="instrumentValue__name">{detail?.name}</div>
            <div className="instrumentValue__ticker">
              <Tag color="blue">{detail?.ticker}</Tag>
            </div>
          </div>
        )}
      />
    </>
  );
};


export default InstrumentValue
