import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"

import { useLazyGetDetailQuery } from 'models/Instrument/api'
import { DetailPage } from 'layout'
import { InstrumentDetail } from 'models/Instrument/components'
import { RobotGroupForm } from 'models/RobotGroup/components'


const InstrumentDetailPage = () => {
  const { ticker } = useParams();

  const activeBroker = useSelector(state => state.Broker.active);
  const detail = useSelector(state => state.Instrument.detail);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();

  const ref = useRef(null);
  const [editRobotGroupFormState, setEditRobotGroupFormState] = useState({});

  return (
    <DetailPage
      objectId={ticker}
      detail={detail}
      title={`${detail?.ticker}: ${detail?.name}`}
      showBack={true}
      basePath={`/instrument/${ticker}/`}

      getObjectDetail={(ticker) => getObjectDetail({ brokerId: activeBroker?.id, ticker })}
      getObjectDetailProps={getObjectDetailProps}

      actions={[]}

      tabs={[
        { label: 'Основное', key: '' },
        { label: 'Роботы', key: 'robots' },
      ]}

      render={(editFormState, onFormClose) => (
        <>
          <InstrumentDetail detail={detail} />

          <RobotGroupForm
            initials={{ instrument: detail?.ticker }}
            onClose={(isUpdated) => {
              setEditRobotGroupFormState({});
              isUpdated && ref.current && ref.current.loadData();
            }}
            {...editRobotGroupFormState}
          />
        </>
      )}
    />
  );
}

export default InstrumentDetailPage
