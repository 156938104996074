import moment from 'moment-timezone'

import { useBrokerWS } from 'models/Broker/useHooks'

import './styles.css'


const BrokerChooser = () => {
  const { brokerStatus } = useBrokerWS();

  const serverTime = brokerStatus?.time;
  const isConnected = brokerStatus?.isConnected && moment().tz('Europe/Moscow').diff(serverTime, 'seconds') <= 30;

  return (
    <div className="brokerChooser">
      <div className="brokerChooserTitle">Брокер</div>

      <div className="brokerChooserPanel">
        <span className="brokerChooserPanel__name">Тинькофф</span>
      </div>

      {isConnected ?
        <div className="statusConnected" title="Включено" />
        :
        <div className="statusDisconnected" title="Отключено" />
      }

      <div className="serverTime" title="Московское время">
        <div className="serverTime__time">
          {serverTime && serverTime.format('DD.MM.YYYY HH:mm')}
        </div>
      </div>
    </div>
  );
}

export default BrokerChooser
