import { Tag } from 'antd'


import './styles.css'


const STATUS_LIST = [
  ['active', 'активный'],
  ['restoring', 'восстановл.'],
  ['closing', 'закр. позиций'],
]

const STATUS = STATUS_LIST.reduce((res, item) => {
  res[item[0]] = item[1];
  return res;
}, {})


const RobotInstrumentStatus = ({ status, style }) => {
  return (
    <Tag className={`robotStatus ${status}`} style={style}>{STATUS[status] || status}</Tag>
  );
};

RobotInstrumentStatus.STATUS_LIST = STATUS_LIST;
RobotInstrumentStatus.STATUS = STATUS;


export default RobotInstrumentStatus
