import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"

import { Page } from 'layout'


const DetailPage = ({ objectId, basePath, title, showBack, breadcrumbs, tabs, actions, headerEnd, getObjectDetail, getObjectDetailProps, itemDelete, itemDeleteProps, render }) => {
  const navigate = useNavigate();

  const [editFormState, setEditFormState] = useState({});

  useEffect(() => {
    getObjectDetail(objectId);
    // eslint-disable-next-line
  }, [objectId]);

  useEffect(() => {
    if (itemDeleteProps?.isSuccess) {
      navigate(basePath)
    }
    // eslint-disable-next-line
  }, [itemDeleteProps?.isSuccess]);

  useEffect(() => {
    if (getObjectDetailProps.isError) {
      navigate('/404/', { replace: true })
    }
    // eslint-disable-next-line
  }, [getObjectDetailProps.isError, getObjectDetailProps.error?.status]);

  const onEdit = () => {
    setEditFormState({ visible: true, objectId });
  }

  const onDelete = () => {
    itemDelete(objectId)
  }

  const onFormClose = (isUpdated) => {
    setEditFormState({});
    isUpdated && getObjectDetail(objectId);
  }

  const pageActions = actions || [
    { mode: 'button', name: 'edit', action: onEdit },
    {
      mode: 'group',
      actions: [{ name: 'delete', action: onDelete }],
    }
  ];

  return (
    <Page
      isLoading={getObjectDetailProps.isFetching}
      title={title}
      showBack={showBack}
      breadcrumbs={breadcrumbs}
      actions={pageActions}
      headerEnd={headerEnd}
      basePath={basePath}
      tabs={tabs}
    >
      {render(editFormState, onFormClose)}
    </Page>
  );
}

export default DetailPage
