import { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'

import { appInit } from 'redux/features/appSlice'
import { AppLayout } from 'layout'
import LoginPage from 'pages/LoginPage'
import Page404 from 'pages/Page404'
import ExchangePage from 'pages/ExchangePage'
import ExchangeDetailPage from 'pages/ExchangeDetailPage'
import InstrumentPage from 'pages/InstrumentPage'
import InstrumentDetailPage from 'pages/InstrumentDetailPage'
import InstrumentDetailRobotsPage from 'pages/InstrumentDetailRobotsPage'
import RobotInstrumentListPage from 'pages/RobotInstrumentListPage'
import RobotInstrumentDetailPage from 'pages/RobotInstrumentDetailPage'
import RobotListPage from 'pages/RobotListPage'
import RobotGroupPage from 'pages/RobotGroupPage'
import RobotGroupDetailPage from 'pages/RobotGroupDetailPage'
import RobotDetailPage from 'pages/RobotDetailPage'
import AccountPage from 'pages/AccountPage'
import AccountDetailPage from 'pages/AccountDetailPage'


const ProtectedRoute = () => {
  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  const location = useLocation();

  return token ? (
    <Outlet />
  ) : token && user ? (
    <Navigate to='/' state={{ replace: true }} />
  ) : (
    <Navigate to='/login/' state={{ from: location, replace: true }} />
  );
}


const AddTrailingSlash = ({ ...rest }) => {
  const location = useLocation();

  if (location.pathname === "/") {
    return (
      <Navigate
        replace {...rest}
        to={{
          pathname: "/robot/",
          search: location.search
        }}
      />
    )
  }

  if (!location.pathname.endsWith("/")) {
    return (
      <Navigate
        replace {...rest}
        to={{
          pathname: location.pathname + "/",
          search: location.search
        }}
      />
    )
  } else return null
}


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appInit());
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <AddTrailingSlash />
      <Routes>
        <Route path='/login/' element={<LoginPage />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<AppLayout />}>
            <Route path="/robot/">
              <Route index element={<RobotListPage />} />
              <Route path='by/:status/' element={<RobotListPage />} />
              <Route path=':robotId/' element={<RobotDetailPage />} />
            </Route>

            <Route path="/robot-instrument/">
              <Route index element={<RobotInstrumentListPage />} />
              <Route path=':ticker/' element={<RobotInstrumentDetailPage />} />
            </Route>

            <Route path="/robotGroup/">
              <Route index element={<RobotGroupPage />} />
              <Route path=':robotGroupId/' element={<RobotGroupDetailPage />} />
            </Route>

            <Route path="/exchange/">
              <Route index element={<ExchangePage />} />
              <Route path=':exchangeCode/' element={<ExchangeDetailPage />} />
            </Route>

            <Route path="/account/">
              <Route index element={<AccountPage />} />
              <Route path=':accountId/' element={<AccountDetailPage />} />
            </Route>

            <Route path="/instrument/">
              <Route index element={<InstrumentPage />} />
              <Route path='by/:exchangeCode/' element={<InstrumentPage />} />
              <Route path=':ticker/' element={<InstrumentDetailPage />} />
              <Route path=':ticker/robots/' element={<InstrumentDetailRobotsPage />} />
            </Route>
          </Route>
        </Route>

        <Route path='*' element={<Page404 />} />
      </Routes>
    </>
  );
}

export default App
