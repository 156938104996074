import React from 'react'
import moment from 'moment-timezone'


const DateTimeValue = ({ className, style, date, format = 'DD.MM.YYYY HH:mm:ss', size = 14 }) => {
  let text = '';

  if (!date) {
    text = '-';
  } else if (date.length === 10) {
    text = moment(date).format(format);
  } else {
    text = moment(date).tz('Europe/Moscow').format(format);
  }

  let _style = Object.assign(style || {}, { fontSize: `${size}px` });

  return <div className={className} style={_style}>{text}</div>;
};

export default DateTimeValue;
