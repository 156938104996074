import React, { useMemo } from 'react'
import classNames from 'classnames'

import { isDict } from 'utils/helpers'

import styles from './error.module.css'


const FormError = ({ className, error }) => {
  const errors = useMemo(() => {
    if (!error?.data) return '';
    const errorData = error.data;
    if (errorData?.non_field_errors) {
      return error.data.non_field_errors
    }
    if (isDict(errorData)) {
      return null;
    }
    return 'Не предвиденная ошибка';
  }, [error]);

  const cx = classNames([
    styles.error,
    className,
  ]);

  if (!errors) return null;

  return (
    <div className={cx}>{errors}</div>
  );
};

export default FormError;
