import { api } from 'redux/api/api'


const InstrumentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInstrumentList: builder.query({
      query: ({ params = null }) => {
        return {
          url: `broker/instrument/`,
          params: params,
        }
      }
    }),
    getInstrumentSimpleList: builder.query({
      query: ({ params = null }) => ({
        url: `broker/instrument/?simple=true`,
        params: params,
      }),
    }),
    getInstrumentDetail: builder.query({
      query: ({ ticker }) => ({
        url: `broker/instrument/${ticker}/`,
      }),
    }),
  }),
});

export default InstrumentApi;

const {
  useLazyGetInstrumentListQuery,
  useLazyGetInstrumentSimpleListQuery,
  useLazyGetInstrumentDetailQuery,
} = InstrumentApi;

export {
  useLazyGetInstrumentListQuery as useLazyGetListQuery,
  useLazyGetInstrumentSimpleListQuery as useLazyGetSimpleListQuery,
  useLazyGetInstrumentDetailQuery as useLazyGetDetailQuery,
};
