import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { useLazyGetForEditQuery, useUpdateMutation } from 'models/RobotGroup/api'
import Form from 'components/Form'
import MainFieldSet from './MainFieldSet'


const RobotGroupForm = ({ visible, objectId, initials, onClose }) => {
  const detailForEdit = useSelector(state => state.RobotGroup.detailForEdit);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetForEditQuery();
  const [update, updateProps] = useUpdateMutation();

  const onFinish = (values) => {
    const startTimes = values.startTimes.map(time => time.format('HH:mm'));
    const data = { ...values, startTimes };
    console.log(data);
    update(data);
  };

  return (
    <Form
      name=""
      title={`Редактирование группы роботов ${objectId || ''}`}
      width={700}
      visible={visible}
      objectId={objectId}
      detailForEdit={detailForEdit}
      getObjectDetail={(id) => getObjectDetail({ id })}
      getObjectDetailProps={getObjectDetailProps}
      updateProps={updateProps}

      onFinish={onFinish}
      onClose={onClose}
      initialValues={initials}

      prepareFormValues={(detail) => {
        return { ...detail, instrument: detail.instrument.ticker, startTimes: detail.startTimes.map(timeStr => moment(timeStr, 'HH:mm')) }
      }}
    >
      <MainFieldSet detailForEdit={detailForEdit} initials={initials} />
    </Form>
  );
}


export default RobotGroupForm
