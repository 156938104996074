import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import PageHeaderTabs from 'layout/PageHeader/PageHeaderTabs'
import { RobotList } from 'models/Robot/components'


const STATUS_TABS = [
  { getLabel: (count) => `в позиции (${count})`, key: `position` },
  { getLabel: (count) => `сбор данных (${count})`, key: `tracking` },
  { getLabel: (count) => `стоп-переворот (${count})`, key: `flipping` },
  { getLabel: (count) => `завершено (${count})`, key: `completed` },
  // { getLabel: (count) => <><StarFilled />избранное ({count})</>, key: `favorite` },
]


const RobotListWithNav = ({ filterParams }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get('status');

  const objectListInfo = useSelector(state => state.Robot.listInfo);
  const statuses = objectListInfo?.statuses || {};

  useEffect(() => {
    if (!status) {
      const key = STATUS_TABS[0].key;
      navigate({ search: `?status=${key}` }, { replace: true });
    }
    // eslint-disable-next-line
  }, [status]);

  const positionCount = (statuses.position || 0) + (statuses.positionOpening || 0);
  const trackingCount = statuses.tracking || 0;
  const flippingCount = (statuses.flipPause || 0) + (statuses.flipping || 0);
  const completedCount = (statuses.completing || 0) + (statuses.completed || 0);

  const tabs = [
    { label: `в позиции: ${positionCount}`, key: `position` },
    { label: `сбор данных: ${trackingCount}`, key: `tracking` },
    { label: `стоп-переворот: ${flippingCount}`, key: `flipping` },
    { label: `завершено: ${completedCount}`, key: `completed` },
    // {label: <><StarFilled />избранное ({count})</>, key: `favorite` },
  ];

  const onTabChange = (key) => {
    navigate({ search: `?status=${key}` }, { replace: true });
  }

  return (
    <div>
      <PageHeaderTabs tabs={tabs} onTabChange={onTabChange} />

      <br />

      {status && <RobotList showFilter={true} showSearch={true} filterParams={{ ...filterParams, status: status }} />}
    </div>
  );
};

export default RobotListWithNav
