import { numberWithCommas } from 'utils/format'

import './styles.css'


const AccountMoney = ({ moneyList, currency }) => {
  const money = moneyList.filter(item => item.currency === currency)[0];
  if (!money) {
    return '-';
  }
  return (
    <div className="accountMoney">{numberWithCommas(money.amount)} {money.currency}</div>
  );
};


export default AccountMoney
