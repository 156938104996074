import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"

import { useLazyGetDetailQuery, useUpdateMutation } from 'models/Robot/api'
import { DetailPage } from 'layout'
import Favorite from 'components/Favorite'
import { RobotDetail } from 'models/Robot/components'
import { RobotLogList } from 'models/RobotLog/components'


const RobotDetailPage = () => {
  const { robotId } = useParams();

  const activeBroker = useSelector(state => state.Broker.active);
  const detail = useSelector(state => state.Robot.detail);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();
  const [itemUpdate, itemUpdateProps] = useUpdateMutation();

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      getObjectDetail({ brokerId: activeBroker?.id, id: robotId });
    }
    // eslint-disable-next-line
  }, [robotId, itemUpdateProps.isSuccess]);

  return (
    <DetailPage
      objectId={robotId}
      detail={detail}
      title={`Робот №${robotId}: ${detail?.instrument.ticker}`}
      showBack={true}
      basePath={'/robot/'}
      actions={[]}
      headerEnd1={
        <Favorite
          detail={detail}
          fieldName="isFavorite"
          addData={{ broker: activeBroker?.id }}
          itemUpdate={itemUpdate}
          itemUpdateProps={itemUpdateProps}
        />
      }

      getObjectDetail={(id) => getObjectDetail({ brokerId: activeBroker?.id, id })}
      getObjectDetailProps={getObjectDetailProps}

      render={(editFormState, onFormClose) => (
        <>
          <RobotDetail detailId={robotId} detail={detail} />

          <RobotLogList
            title="Лог"
            filterParams={{ robot: robotId }}
          />
        </>
      )}
    />
  );
}

export default RobotDetailPage
