import React from 'react'
import { Form } from 'antd'

import AccountSelect from '../AccountSelect'


const AccountSelectField = ({ name, label, required, disabled }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <AccountSelect required={required} disabled={disabled} />
    </Form.Item>
  );
}

AccountSelectField.getQueryParams = (key, value) => {
  return { [key]: value };
}

AccountSelectField.getValue = (values) => {
  return values;
}

AccountSelectField.getDisplayValue = (values, config) => {
  return values
};

export default AccountSelectField
