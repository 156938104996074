import React from 'react'
import classNames from 'classnames'

import "./styles.css"


const Fieldset = ({ className, title, actions, children, style }) => {
  const withHeader = !!title || !!actions;

  const cx = classNames(["fieldset", withHeader && "fieldset_withHeader", className]);

  return (
    <div className={cx} style={style}>
      {withHeader && (
        <div className="fieldset__header">
          <div className="fieldset__headerMain">
            <div className="fieldset__title">{title}</div>
          </div>
          {actions && actions.length && <div className="fieldset__actions">{actions}</div>}
        </div>
      )}

      <div className="fieldset__content">{children}</div>
    </div>
  );
};

export default Fieldset;
