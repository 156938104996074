import React from 'react'
import { Form } from 'antd'

import RobotGroupSelect from '../RobotGroupSelect'


const RobotGroupSelectField = ({ name, label, required, disabled }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <RobotGroupSelect required={required} disabled={disabled} />
    </Form.Item>
  );
}

RobotGroupSelectField.getQueryParams = (key, value) => {
  return { [key]: value };
}

RobotGroupSelectField.getValue = (values) => {
  return values;
}

RobotGroupSelectField.getDisplayValue = (values, config) => {
  return values
};

export default RobotGroupSelectField
