import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"

import { useLazyGetDetailQuery } from 'models/Exchange/api'
import { DetailPage, } from 'layout'
import { ExchangeDetail } from 'models/Exchange/components'


const ExchangeDetailPage = () => {
  const { exchangeCode } = useParams();

  const detail = useSelector(state => state.Exchange.detail);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();

  return (
    <DetailPage
      objectId={exchangeCode}
      detail={detail}
      title={detail?.name}
      showBack={true}
      basePath={'/exchange/'}

      getObjectDetail={getObjectDetail}
      getObjectDetailProps={getObjectDetailProps}

      actions={[]}
      render={(editFormState, onFormClose) => (
        <>
          <ExchangeDetail exchangeCode={exchangeCode} detail={detail} />
        </>
      )}
    />
  );
}

export default ExchangeDetailPage
