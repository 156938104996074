import React, { useState, useEffect } from 'react'
import { Switch } from 'antd'


const OnOff = ({ detail, fieldName, itemUpdate, itemUpdateProps, pkField = 'id', addData }) => {
  const [loading, setLoading] = useState(false);

  const onChange = data => {
    if (!loading) {
      setLoading(true);
      if (addData) {
        data = { ...data, ...addData }
      }
      itemUpdate(data);
    }
  }

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [itemUpdateProps.isSuccess]);

  return (
    <Switch
      checkedChildren="вкл"
      unCheckedChildren="выкл"
      checked={detail && detail[fieldName]}
      loading={loading}
      onClick={() => {
        onChange({ [pkField]: detail[pkField], [fieldName]: !detail[fieldName] });
      }}
    />
  );
};

export default OnOff;
