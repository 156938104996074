import React from 'react'
import { Form } from 'antd'

import ExchangeSelect from '../ExchangeSelect'


const ExchangeSelectField = ({ name, label, required, disabled }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <ExchangeSelect required={required} disabled={disabled} />
    </Form.Item>
  );
}

ExchangeSelectField.getQueryParams = (key, value) => {
  return { [key]: value };
}

ExchangeSelectField.getValue = (values) => {
  return values;
}

ExchangeSelectField.getDisplayValue = (values, config) => {
  return values
};

export default ExchangeSelectField
