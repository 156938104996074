import React from 'react'
import LoginForm from 'components/LoginForm';

import styles from './screen.module.css';

const LoginPage = () => {
  return (
    <div className={styles.main}>
      <div className={styles.panel}>
        <div>
          <div className={styles.title}>Вход</div>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
