import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom"

import { useLazyGetDetailQuery, useDeleteMutation } from 'models/RobotGroup/api'
import { DetailPage } from 'layout'
import { RobotGroupDetail, RobotGroupForm } from 'models/RobotGroup/components'
import { RobotList } from 'models/Robot/components'


const RobotGroupDetailPage = () => {
  const { robotGroupId } = useParams();

  const activeBroker = useSelector(state => state.Broker.active);
  const detail = useSelector(state => state.RobotGroup.detail);
  const [getObjectDetail, getObjectDetailProps] = useLazyGetDetailQuery();
  const [itemDelete] = useDeleteMutation();

  const loadObjectDetail = (id) => {
    getObjectDetail({ brokerId: activeBroker?.id, id });
  }

  const [editFormState, setEditFormState] = useState({});
  const onEditFormClose = (isUpdated) => {
    setEditFormState({});
    isUpdated && loadObjectDetail(robotGroupId);
  }

  const onEdit = () => {
    setEditFormState({ visible: true, objectId: robotGroupId });
  }

  const onDelete = () => {
    itemDelete(robotGroupId)
  }

  return (
    <DetailPage
      objectId={robotGroupId}
      detail={detail}
      title={`Группа роботов №${robotGroupId}: ${detail?.instrument.ticker}`}
      showBack={true}
      basePath={'/robotGroup/'}

      getObjectDetail={loadObjectDetail}
      getObjectDetailProps={getObjectDetailProps}

      actions={[
        { mode: 'button', name: 'edit', action: onEdit },
        {
          mode: 'group',
          actions: [{ name: 'delete', action: onDelete }],
        }
      ]}

      render={() => (
        <>
          <RobotGroupDetail detailId={robotGroupId} detail={detail} />

          <RobotList
            title="Роботы"
            robotGroup={detail}
            filterParams={{ group: robotGroupId }}
          />

          <RobotGroupForm
            initials={{ instrument: detail?.instrument.ticker }}
            onClose={onEditFormClose}
            {...editFormState}
          />
        </>
      )}
    />
  );
}

export default RobotGroupDetailPage
