import React from 'react'
import { Form } from 'antd'

import BrokerSelect from '../BrokerSelect'


const BrokerSelectField = ({ name, label, required, disabled }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: required }]}>
      <BrokerSelect required={required} disabled={disabled} />
    </Form.Item>
  );
}

BrokerSelectField.getQueryParams = (key, value) => {
  return { [key]: value };
}

BrokerSelectField.getValue = (values) => {
  return values;
}

BrokerSelectField.getDisplayValue = (values, config) => {
  return values
};

export default BrokerSelectField
