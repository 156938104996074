import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  list: [],
  allCount: null,

  detailForEdit: null,
  detail: null,
};

export const RobotLog = createSlice({
  initialState,
  name: 'RobotLog',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getRobotLogList.matchFulfilled, (state, action) => {
        state.list = action.payload.results;
        state.allCount = action.payload.count;
      })
      .addMatcher(api.endpoints.getRobotLogDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })
      .addMatcher(api.endpoints.getRobotLogForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.getRobotLogForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })
  },
});

export default RobotLog.reducer;
