import { Row, Col, Button, Form, Space } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { NumberField, SwitchField, SelectField, TimeField } from 'components/FormFields'
import { InstrumentSelectField } from 'models/Instrument/components'


const MainFieldSet = ({ detailForEdit, initials }) => {
  console.log('detailForEdit', detailForEdit)
  return (
    <>
      <InstrumentSelectField
        label="Инструмент"
        name="instrument"
        required={true}
        disabled={!!initials?.instrument || !!detailForEdit?.isOn}
      />

      <Row gutter={[32, 32]} style={{ marginBottom: '16px' }}>
        <Col span={8}>
          <SelectField
            label="Cтартовая позиция"
            name="startDirection"
            required={true}
            options={[
              { value: 1, label: 'Long' },
              { value: 2, label: 'Short' },
            ]}
            disabled={!!detailForEdit?.isOn}
          />
        </Col>

        <Col span={4}>
          <NumberField label="Профит" name="profit" required={true} disabled={!!detailForEdit?.isOn} />
        </Col>

        <Col span={5}>
          <NumberField
            label="Отступ"
            name="ots"
            required={true}
            min="0.01"
            max="100"
            step="0.01"
            addonAfter="%"
            disabled={!!detailForEdit?.isOn}
          />
        </Col>

        <Col span={7}>
          <NumberField label="Коэфф. увеличения" name="koef" required={true} disabled={!!detailForEdit?.isOn} />
        </Col>
      </Row>

      <Row gutter={[32, 32]} style={{ marginBottom: '16px' }}>
        <Col span={12}>
          <NumberField
            label="Время первоначального сбора"
            name="time"
            required={true}
            addonAfter="мин"
            disabled={!!detailForEdit?.isOn}
          />
        </Col>
        <Col span={12}>
          <NumberField
            label="Пауза при стоп-перевороте"
            name="flipPause"
            required={true}
            addonAfter="мин"
            disabled={!!detailForEdit?.isOn}
          />
        </Col>
      </Row>

      <SwitchField label="Включён" name="isOn" />

      <fieldset>
        <legend>Время запуска</legend>
        <Form.List name="startTimes" rules={[{ required: true }]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space key={field.key} align="center" style={{ margin: '0 20px 16px 0' }}>
                  <TimeField
                    name={field.name}
                    required={true}
                    minuteStep={10}
                    showNow={false}
                  />

                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}

              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Добавить время
              </Button>
            </>
          )}
        </Form.List>
      </fieldset>
    </>
  );
};

export default MainFieldSet;
