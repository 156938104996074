import { simpleNumber } from 'utils/format'
import NumberValue from 'components/NumberValue'

import './styles.css'


const RobotCurrentProfit = ({ robot, style }) => {
  if (!robot || robot.position === 'none') return null;
  const lastPrice = simpleNumber(robot.lastPrice);
  const price = simpleNumber(robot.price);
  const loss = simpleNumber(robot.loss);
  const quantity = simpleNumber(robot.quantity);

  let profitTitle = `${quantity}x(${lastPrice}-${price})-${loss}`
  if (robot.position === 'short') {
    profitTitle = `${quantity}x(${price}-${lastPrice})-${loss}`
  }

  const currency = robot.instrument.instrumentType === 'future' ? 'пт.': robot.instrument.currency;

  return (
    <div className="currentProfit">
      <div className="currentProfitItem">
        <span className="currentProfitItem__lbl">Тек. профит:</span>
        <span className="currentProfitItem__val"><NumberValue value={robot.currentProfit} title={profitTitle} currency={currency} /></span>
      </div>
      <div className="currentProfitItem">
        <span className="currentProfitItem__lbl">Нак. убыток:</span>
        <span className="currentProfitItem__val"><NumberValue value={-robot.loss} currency={currency} /></span>
      </div>
    </div>
  );
};

export default RobotCurrentProfit;
