import { numberWithCommas } from 'utils/format'
import TableList from 'components/TableList'

const defaultColumnList = ['amount', 'currency'];


const AccountMoneyList = ({ columnList, moneyList }) => {
  const objectList = moneyList;

  if (!columnList) {
    columnList = defaultColumnList;
  }

  const fields = {
    id: {
      title: 'ID',
      sorter: true,
      width: 80,
    },
    amount: {
      title: 'Сумма',
      render: (val, item) => numberWithCommas(val),
    },
    currency: {
      title: 'Валюта',
      width: 130,
    },
  };

  return (
    <TableList
      fields={fields}
      columnList={columnList}
      objectList={objectList}
      allCount={objectList.length}

      showSearch={false}
      rowSelection={false}
    />
  );
}

export default AccountMoneyList;
