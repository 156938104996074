import { useEffect } from 'react'

import { useUpdateMutation, useLazyGetDetailQuery } from 'models/Exchange/api'
import Fieldset from 'components/Fieldset'
import Descriptions from 'components/Descriptions'
import DateTimeValue from 'components/DateTimeValue'
import OnOff from 'components/OnOff'
import ExchangeTradingDayList from '../ExchangeTradingDayList'


const ExchangeDetail = ({ exchangeCode, detail }) => {
  const [itemUpdate, itemUpdateProps] = useUpdateMutation();
  const [getObjectDetail] = useLazyGetDetailQuery();

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      getObjectDetail(exchangeCode);
    }
    // eslint-disable-next-line
  }, [exchangeCode, itemUpdateProps.isSuccess]);

  return (
    <>
      <Fieldset>
        <Descriptions size="sm">
          <Descriptions.Item md={6} label="Включено">
            <OnOff detail={detail} fieldName="isForRobot" pkField="code" itemUpdate={itemUpdate} itemUpdateProps={itemUpdateProps} />
          </Descriptions.Item>

          <Descriptions.Item md={6} label="Название">{detail?.name}</Descriptions.Item>

          <Descriptions.Item md={6} label="Код">{detail?.code}</Descriptions.Item>

          <Descriptions.Item md={6} label="Дата синхронизации">
            <DateTimeValue date={detail?.dateSynced} />
          </Descriptions.Item>
        </Descriptions>
      </Fieldset>

      <Fieldset title="Расписание" size="sm">
        <ExchangeTradingDayList days={detail?.days || []} />
      </Fieldset>
    </>
  );
}

export default ExchangeDetail
