import { api } from 'redux/api/api'


const ExchangeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getExchangeList: builder.query({
      query: (params = null) => {
        return {
          url: 'broker/exchange/',
          params: params,
        }
      }
    }),
    getExchangeSimpleList: builder.query({
      query: (params = null) => ({
        url: 'broker/exchange/',
        params: params,
      }),
    }),
    getExchangeDetail: builder.query({
      query: (code) => ({
        url: `broker/exchange/${code}/`,
      }),
    }),
    getExchangeForEdit: builder.query({
      query: (code) => ({
        url: `broker/exchange/${code}/`,
      }),
    }),

    updateExchange: builder.mutation({
      query(values) {
        console.log('values', values)
        const url = values.code ? `broker/exchange/${values.code}/`: 'broker/exchange/';
        const method = values.code ? `PUT`: 'POST';
        return {
          url: url,
          method: method,
          body: values,
        };
      },
    }),
    deleteExchange: builder.mutation({
      query(code) {
        return {
          url: `broker/exchange/${code}/`,
          method: 'DELETE',
        };
      },
    }),
    batchDeleteExchange: builder.mutation({
      query(selected) {
        return {
          url: 'broker/exchange/delete/',
          method: 'POST',
          body: { selected },
        };
      },
    }),
  }),
});

export default ExchangeApi;

const {
  useLazyGetExchangeListQuery,
  useLazyGetExchangeSimpleListQuery,
  useLazyGetExchangeDetailQuery,
  useLazyGetExchangeForEditQuery,
  useUpdateExchangeMutation,
  useDeleteExchangeMutation,
  useBatchDeleteExchangeMutation,
} = ExchangeApi;

export {
  useLazyGetExchangeListQuery as useLazyGetListQuery,
  useLazyGetExchangeSimpleListQuery as useLazyGetSimpleListQuery,
  useLazyGetExchangeDetailQuery as useLazyGetDetailQuery,
  useLazyGetExchangeForEditQuery as useLazyGetForEditQuery,
  useUpdateExchangeMutation as useUpdateMutation,
  useDeleteExchangeMutation as useDeleteMutation,
  useBatchDeleteExchangeMutation as useBatchDeleteMutation,
};
