import { Tag } from 'antd'


import './styles.css'


const STATUS_LIST = [
  ['tracking', 'сбор данных'],
  ['positionOpening', 'открытие позиции'],
  ['position', 'в позиции'],
  ['flipPause', 'стоп-переворот: пауза'],
  ['flipping', 'стоп-переворот'],
  ['completing', 'завершение'],
  ['complete', 'завершено'],
]

const STATUS = STATUS_LIST.reduce((res, item) => {
  res[item[0]] = item[1];
  return res;
}, {})


const CHANGE_STATUS = {
  'active': 'активно',
  'restoring': 'восстановление',
  'archiving': 'архивация',
  'archived': 'в архиве',
}

const RobotStatus = ({ status, changeStatus, style }) => {
  let chTitle = '';
  if (changeStatus !== 'active') {
    chTitle = (CHANGE_STATUS[changeStatus] || changeStatus) + ': ';
  }
  return (
      <Tag className={`robotStatus ${status}`} style={style}>{chTitle}{STATUS[status] || status}</Tag>
  );
};

RobotStatus.STATUS_LIST = STATUS_LIST;
RobotStatus.STATUS = STATUS;


export default RobotStatus
