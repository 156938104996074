import React from 'react'
import { Form, Select } from 'antd'


const FilterItemSelect = ({ label, field, options, size }) => {
  return (
    <Form.Item name={field} label={label}>
      <Select allowClear={true} options={options} size={size} style={{ minWidth: '100px' }} />
    </Form.Item>
  );
}

FilterItemSelect.getQueryParams = (key, value) => {
  return { [key]: value };
}

FilterItemSelect.getValue = (values) => {
  return values;
}

FilterItemSelect.getDisplayValue = (value, config) => {
  const result = config.options.find(opt => {
    return opt.value === value
  })
  return result ? result.label : value;
}

export default FilterItemSelect;
