import { api } from 'redux/api/api'


const RobotLogApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRobotLogList: builder.query({
      query: (params = null) => {
        return {
          url: 'robot/log/',
          params: params,
        }
      }
    }),
    getRobotLogSimpleList: builder.query({
      query: (params = null) => ({
        url: 'robot/log/',
        params: params,
      }),
    }),
    getRobotLogDetail: builder.query({
      query: (id) => ({
        url: `robot/log/${id}/`,
      }),
    }),
    getRobotLogForEdit: builder.query({
      query: (id) => ({
        url: `robot/log/${id}/`,
      }),
    }),
  }),
});

export default RobotLogApi;

const {
  useLazyGetRobotLogListQuery,
  useLazyGetRobotLogSimpleListQuery,
  useLazyGetRobotLogDetailQuery,
  useLazyGetRobotLogForEditQuery,
} = RobotLogApi;

export {
  useLazyGetRobotLogListQuery as useLazyGetListQuery,
  useLazyGetRobotLogSimpleListQuery as useLazyGetSimpleListQuery,
  useLazyGetRobotLogDetailQuery as useLazyGetDetailQuery,
  useLazyGetRobotLogForEditQuery as useLazyGetForEditQuery,
};
