import React from 'react'
import { Drawer } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import FilterForm from '../FilterForm'
import './styles.css'


const FilterDrawer = ({ filterFields, searchParams, objectSearchParams, queryKey, onLoadData, visible, onToggle }) => {
  return (
    <Drawer
      title="Фильтры"
      open={visible}
      width={300}
      onClose={onToggle}
      placement="right"
      destroyOnClose={true}
      forceRender={true}
      handler={
        visible && (
          <div className="filter-handle" onClick={onToggle}>
            <CloseOutlined />
          </div>
        )
      }
      style={{
        zIndex: 999,
      }}
    >
      <FilterForm
        layout="vertical"
        onLoadData={onLoadData}
        searchParams={searchParams}
        objectSearchParams={objectSearchParams}
        queryKey={queryKey}
        filterFields={filterFields}
      />
    </Drawer>
  );
};


export default FilterDrawer;
