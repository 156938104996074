const ENV = {
  local: {
    apiUrl: 'http://192.168.88.100:8000',
    wsUrl: 'ws://localhost:8000/ws/',
  },
  production: {
    apiUrl: 'https://trader-api.treeweb.top',
    wsUrl: 'wss://trader-logux.treeweb.top/ws/',
  },
};

const urls = ENV['production'];

export const API_URL = urls.apiUrl;
export const WS_URL = urls.wsUrl;
