import { Tag } from 'antd'

import DetailValue from 'components/DetailValue'

import './styles.css'


const RobotInstrumentValue = ({ detail, asLink, isBack, style }) => {
  return (
    <>
      <DetailValue 
        linkUrl={asLink && `/robot-instrument/${detail?.instrument?.ticker}/`} 
        isBack={isBack} 
        render={() => (
          <div className={`instrumentValue ${detail?.changeStatus !== 'active' && 'archive'}`} style={style}>
            <div className="instrumentValue__name">{detail?.instrument?.name}</div>
            <div className="instrumentValue__ticker">
              <Tag color="blue">{detail?.instrument?.ticker}</Tag>
            </div>
          </div>
        )}
      />
    </>
  );
};


export default RobotInstrumentValue
