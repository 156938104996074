import React from 'react'
import { Form, DatePicker } from 'antd'
import moment from 'moment'

import './styles.css'


const FilterItemDateRange = ({ label, field, size }) => {
  return (
    <Form.Item name={field} label={label}>
      <DatePicker.RangePicker
        className="filterRange"
        format={"DD.MM.YYYY"}
        size={size}
        locale={{
          "lang": {
            "locale": "ru_RU",
            "placeholder": "Select date",
            "rangePlaceholder": ["Дата от", "Дата до"],
            "today": "Сегодня",
            "now": "Сейчас",
            "backToToday": "Назад к сегодня",
            "ok": "OK",
            "clear": "Очистить",
            "month": "Месяц",
            "year": "Год",
            "timeSelect": "Выбрать время",
            "dateSelect": "Выбрать дату",
            "monthSelect": "Выбрать месяц",
            "yearSelect": "Выбрать год",
            "decadeSelect": "Выбрать декаду",
            "yearFormat": "YYYY",
            "dateFormat": "DD.MM.YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Previous month (PageUp)",
            "nextMonth": "Next month (PageDown)",
            "previousYear": "Last year (Control + left)",
            "nextYear": "Next year (Control + right)",
            "previousDecade": "Last decade",
            "nextDecade": "Next decade",
            "previousCentury": "Last century",
            "nextCentury": "Next century"
          },
          "timePickerLocale": {
            "placeholder": "Выбрать время"
          },
          "dateFormat": "YYYY-MM-DD",
          "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
          "weekFormat": "YYYY-wo",
          "monthFormat": "YYYY-MM"
        }}
      />
    </Form.Item>
  );
}

FilterItemDateRange.getQueryParams = (key, values) => {
  if (values && values.length) {
    const start = values[0].format("DD.MM.YYYY");
    const end = values[1].format("DD.MM.YYYY");
    return { [key]: `${start}--${end}` };
  }
}

FilterItemDateRange.getValue = (values) => {
  if (values && values.length) {
    const [start, end] = values[0].split('--')
    return [moment(start, "DD.MM.YYYY"), moment(end, "DD.MM.YYYY")];
  }
}

FilterItemDateRange.getDisplayValue = (value, config) => {
  return value;
}

export default FilterItemDateRange;
