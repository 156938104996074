import React, { useEffect } from 'react'
import { Modal, Form, Button, Spin } from 'antd'

import FormError from 'components/FormError'

import "./styles.css"


/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '«${label}» обязательное поле',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};


const ModalForm = ({ visible, objectId, detailForEdit, getObjectDetail, getObjectDetailProps, updateProps, initialValues, onFinish, ...props }) => {
  const [form] = Form.useForm();

  const objId = parseInt(objectId) || objectId;

  useEffect(() => {
    if (objId && objId !== detailForEdit?.id && !getObjectDetailProps.isLoading && !getObjectDetailProps.isError) {
      getObjectDetail(objId);
    }
  }, [detailForEdit?.id, objId, getObjectDetailProps.isLoading, getObjectDetailProps.isError, getObjectDetail]);

  useEffect(() => {
    if (visible) {
      form.resetFields();

      if (objId === detailForEdit?.id) {
        const values = props.prepareFormValues ? props.prepareFormValues(detailForEdit) : { ...detailForEdit };
        form.setFieldsValue(values);
      }
    }
    // eslint-disable-next-line
  }, [visible, detailForEdit]);

  useEffect(() => {
    if (updateProps.isError) {
      const errorData = updateProps.error?.data || {};
      const fields = Object.keys(errorData).map(fieldName => {
        return { name: fieldName, errors: errorData[fieldName] }
      })
      form.setFields(fields);
    }
    // eslint-disable-next-line
  }, [updateProps.isError, updateProps.error?.data]);

  useEffect(() => {
    if (updateProps.isSuccess) {
      props.onClose(true, updateProps.data);
    }
    // eslint-disable-next-line
  }, [updateProps.isSuccess]);

  return (
    <Modal
      className="modalForm"
      width={props.width || 600}
      open={visible}
      onCancel={props.onClose}
      maskClosable={false}
      destroyOnClose={true}
      forceRender={true}
      footer={[
        <Button key="cancel" onClick={props.onClose} disabled={getObjectDetailProps.isLoading}>
          Отмена
        </Button>,
        <Button key="submit" type="primary" loading={getObjectDetailProps.isLoading} onClick={() => form.submit()}>
          Сохранить
        </Button>,
      ]}
    >
      <Spin spinning={getObjectDetailProps.isLoading || updateProps.isLoading}>
        <Form
          name={props.name}
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={values => {
            onFinish && onFinish({ ...values, id: objId })
          }}
          onFinishFailed={props.onFinishFailed}
          autoComplete="off"
          validateMessages={validateMessages}
        >
          <div className={`modalFormHeader ${props.withTabs && "modalFormHeader__withTabs"}`}>
            <div className="modalFormTitle">{props.title}</div>
          </div>
          <FormError error={updateProps.error} />
          {props.children}
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalForm;
