import { Skeleton } from 'antd'

import PageHeader from '../PageHeader'
import './styles.css'
import './page_transitions.css'


const Page = ({ children, isLoading, ...headerProps }) => {
  let cls = "page";
  if (!headerProps.title && !headerProps.actions && !headerProps.tabs) cls += " page__withoutHeader";

  return (
    <div className={cls}>
      <PageHeader {...headerProps} />

      {isLoading ?
        <Skeleton active />
        : <div className="pageContent">{children}</div>
      }
    </div>
  );
};

export default Page
