import { createSlice } from '@reduxjs/toolkit'

import api from './api'


const initialState = {
  list: [],
  allCount: null,
  listInfo: {},

  detailForEdit: null,
  detail: null,
};

export const Robot = createSlice({
  initialState,
  name: 'Robot',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getRobotList.matchFulfilled, (state, action) => {
        state.list = action.payload.results;
        state.allCount = action.payload.count;
        state.listInfo = action.payload.info;
      })
      .addMatcher(api.endpoints.getRobotDetail.matchFulfilled, (state, action) => {
        state.detail = action.payload;
      })
      .addMatcher(api.endpoints.getRobotForEdit.matchPending, (state, action) => {
        state.detailForEdit = null;
      })
      .addMatcher(api.endpoints.getRobotForEdit.matchFulfilled, (state, action) => {
        state.detailForEdit = action.payload;
      })
      .addMatcher(api.endpoints.updateRobot.matchFulfilled, (state, action) => {
        state.detailForEdit = null;
      })
  },
});

export default Robot.reducer;
