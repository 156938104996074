import { api } from 'redux/api/api'


const RobotApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRobotList: builder.query({
      query: ({  params = null }) => {
        return {
          url: `robot/robot/`,
          params: params,
        }
      }
    }),
    getRobotSimpleList: builder.query({
      query: ({ params = null }) => ({
        url: `robot/robot/`,
        params: params,
      }),
    }),
    getRobotDetail: builder.query({
      query: ({ id }) => ({
        url: `robot/robot/${id}/`,
      }),
    }),
    getRobotForEdit: builder.query({
      query: ({ id }) => ({
        url: `robot/robot/${id}/`,
      }),
    }),

    updateRobot: builder.mutation({
      query(values) {
        const url = values.id ? `robot/robot/${values.id}/` : `robot/robot/`;
        const method = values.id ? `PUT` : 'POST';
        return {
          url: url,
          method: method,
          body: values,
        };
      },
    }),
  }),
});

export default RobotApi;

const {
  useLazyGetRobotListQuery,
  useLazyGetRobotSimpleListQuery,
  useLazyGetRobotDetailQuery,
  useLazyGetRobotForEditQuery,
  useUpdateRobotMutation,
} = RobotApi;

export {
  useLazyGetRobotListQuery as useLazyGetListQuery,
  useLazyGetRobotSimpleListQuery as useLazyGetSimpleListQuery,
  useLazyGetRobotDetailQuery as useLazyGetDetailQuery,
  useLazyGetRobotForEditQuery as useLazyGetForEditQuery,
  useUpdateRobotMutation as useUpdateMutation,
};
