import { Tooltip } from 'antd';

import { numberWithCommas } from 'utils/format'

import "./styles.css"


const NumberValue = ({ value, fixed, align = "left", title = "", currency = "", mark = true }) => {
  let cls = '';
  if (mark) {
    cls = 'green';
    if (value < 0) {
      cls = 'red';
    }
  }

  if (currency === 'rub') {
    currency = '₽';
  }

  return (
    <Tooltip className={`numberValue ${cls} ${align}`} title={title}>
      {numberWithCommas(value, fixed)}{currency && <span>{currency}</span>}
    </Tooltip>
  );
}

export default NumberValue

