import { Page } from 'layout'
import { RobotListWithNav } from 'models/Robot/components'


const RobotPage = () => {
  return (
    <Page
      title="Роботы"
      basePath="/robot/"
    >
      <RobotListWithNav />
    </Page>
  );
};

export default RobotPage
