import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import { Tag } from 'antd'

import { useLazyGetListQuery } from 'models/RobotLog/api'
import TableList from 'components/TableList'
import DateTimeValue from 'components/DateTimeValue'


const defaultColumnList = ['dateCreated', 'action', 'comment'];


const RobotLogList = forwardRef(({ title, columnList, topActions, onEdit, filterParams }, ref) => {
  const tableRef = useRef(null);

  const objectList = useSelector(state => state.RobotLog.list);
  const allCount = useSelector(state => state.RobotLog.allCount);
  const [getObjectList, getObjectListProps] = useLazyGetListQuery();

  console.log(objectList);

  const fields = {
    id: {
      title: 'ID',
      width: 80,
    },
    dateCreated: {
      title: 'Дата',
      width: 100,
      render: (val, item) => (<DateTimeValue size="14" date={val} />),
    },
    action: {
      title: 'Событие',
      width: 200,
      render: (val, item) => (<Tag>{val}</Tag>),
    },
    comment: {
      title: 'Комментарий',
    },
  };

  function loadData() {
    tableRef.current && tableRef.current.loadData();
  }
  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      title={title}
      filterParams={filterParams}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectList}
      allCount={allCount}
      queryKey="RobotLog"

      getObjectList={getObjectList}
      getObjectListProps={getObjectListProps}
      onEdit={onEdit}

      topActions={topActions}
      showSearch={false}
      rowSelection={false}
    />
  );
});

export default RobotLogList;
