import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import "./styles.css"


const DetailValue = ({ className, linkUrl, isBack, render }) => {
  const location = useLocation();

  const linkState = useMemo(() => {
    let state = { slideIn: true };
    if (isBack) {
      state.backUrl = location.pathname;
      state.backSearch = location.search;
    }
    return state
  }, [isBack, location.pathname, location.search]);

  return (
    <>
      {linkUrl ?
        <Link className={className || "detailLink"} to={linkUrl} state={linkState}>{render()}</Link> :
        <span>{render()}</span>
      }
    </>
  );
}

export default DetailValue
