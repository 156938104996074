import React, { useState, useRef } from 'react'

import { Page } from 'layout'
import { RobotGroupList, RobotGroupForm } from 'models/RobotGroup/components'

import './styles.css'


const RobotGroupPage = () => {
  const ref = useRef(null);
  const [editFormState, setEditFormState] = useState({});

  const onEdit = (objectId = null) => {
    setEditFormState({ visible: true, objectId });
  };

  return (
    <Page
      title="Группы роботов"
    >
      <RobotGroupList showSearch={true} onEdit={onEdit} ref={ref} />

      <RobotGroupForm
        onClose={(isUpdated) => {
          setEditFormState({});
          isUpdated && ref.current && ref.current.loadData();
        }}
        {...editFormState}
      />
    </Page>
  );
};

export default RobotGroupPage
