import React, { useEffect, useMemo } from 'react'
import { useNavigate } from "react-router-dom"
import { Form } from 'antd'

import { getDataQuery } from 'utils/urls'

import './styles.css'


const FilterForm = ({ layout, size, filterFields, searchParams, objectSearchParams, queryKey, onLoadData }) => {
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const filterFieldsByName = useMemo(() => {
    return filterFields.reduce((res, item) => {
      res[item.field] = item;
      return res;
    }, {})
  }, [filterFields]);

  const onValuesChange = (changedValues, allValues) => {
    if (onLoadData) {
      for (let key in allValues) {
        const config = filterFieldsByName[key];
        if (!config) continue;
        const params = config.component.getQueryParams(key, allValues[key]);
        for (let fieldName in params) {
          objectSearchParams.delete(fieldName);

          const value = params[fieldName];
          if (!value) continue;
          if (Array.isArray(value)) {
            //objectSearchParams.delete(fieldName);
            value.forEach(v => {
              objectSearchParams.append(fieldName, v);
            })
          } else {
            objectSearchParams.set(fieldName, value);
          }
        }
      }
      const [dataQuery, searchString] = getDataQuery(searchParams, objectSearchParams, queryKey);
      navigate({ search: searchString, replace: true });
      onLoadData({ dataQuery });
    }
  };

  useEffect(() => {
    const instance = filterFields.reduce((result, config) => {
      const values = objectSearchParams.getAll(config.field);
      result[config.field] = config.component.getValue(values);
      return result;
    }, {});
    form.setFieldsValue(instance);
  }, [form, filterFields, objectSearchParams]);


  return (
    <Form className="filterForm" layout={layout} form={form} onValuesChange={onValuesChange}>
      {filterFields.map(config => {
        const { component, ...componentProps } = config;
        return (
          <config.component key={config.field} name={config.field} size={size} {...componentProps} />
        )
      })}
    </Form>
  );
};


export default FilterForm;
