import React from 'react'
import { Form, Radio } from 'antd'


const FilterItemBool = ({ label, field, size }) => {
  return (
    <Form.Item name={field} label={label}>
      <Radio.Group buttonStyle="solid" size={size}>
        <Radio.Button value="">Все</Radio.Button>
        <Radio.Button value="true">Да</Radio.Button>
        <Radio.Button value="false">Нет</Radio.Button>
      </Radio.Group>
    </Form.Item>
  );
};

FilterItemBool.getQueryParams = (key, value) => {
  return { [key]: value };
}

FilterItemBool.getValue = (values) => {
  if (Array.isArray(values)) values = values[0];
  if (!values) {
    return '';
  }
  return values;
}

FilterItemBool.getDisplayValue = (values, config) => {
  if (values === 'true') return 'Да';
  return 'Нет';
};

export default FilterItemBool;
