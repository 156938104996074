import DateTimeValue from 'components/DateTimeValue'

import './styles.css'


const TradingDay = ({ tradingDay }) => {
  if (!tradingDay) return null;
  return (
    <div className="tradingDay">
      {tradingDay.isTradingDay ?
        <>
          <DateTimeValue className="tradingDay__date" date={tradingDay.startTime} onlyTime={true} />
          -
          <DateTimeValue className="tradingDay__date" date={tradingDay.endTime} onlyTime={true} />
        </>
        :
        <div>-</div>
      }
    </div>
  )
};


export default TradingDay
