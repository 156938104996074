import { Tag } from 'antd'

import DetailValue from 'components/DetailValue'

import './styles.css'


const RobotValue = ({ detail, asLink, isBack }) => {
  const before = detail?.before;
  return (
    <>
      <DetailValue
        className="robotValue"
        linkUrl={asLink && `/robot/${detail.id}/`}
        isBack={isBack}
        render={() => (
          <>
            <div className="robotValueTitle">
              Робот {detail?.id}{before && <span style={{opacity: '.66'}} color="blue"> ← {before.id}</span>}
            </div>
            <Tag color="blue">{detail?.instrument.ticker}</Tag>
            <div className="instrumentName">{detail?.instrument.name}</div>
          </>
        )}
      />
    </>
  );
};


export default RobotValue
