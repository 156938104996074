import { useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

import { useLazyGetListQuery, useUpdateMutation } from 'models/Exchange/api'
import TableList from 'components/TableList'
import { FilterItemBool } from 'components/Filter'
import OnOff from 'components/OnOff'
import DateTimeValue from 'components/DateTimeValue'
import ExchangeValue from '../ExchangeValue'
import TradingDay from '../TradingDay'


const defaultColumnList = ['name', 'tradingDay', 'isForRobot', 'dateSynced'];


const ExchangeList = forwardRef(({ columnList, topActions, onEdit }, ref) => {
  const tableRef = useRef(null);

  const objectList = useSelector(state => state.Exchange.list);
  const allCount = useSelector(state => state.Exchange.allCount);
  const [getObjectList, getObjectListProps] = useLazyGetListQuery();
  const [itemUpdate, itemUpdateProps] = useUpdateMutation();

  if (!columnList) {
    columnList = defaultColumnList;
  }

  useEffect(() => {
    if (itemUpdateProps.isSuccess) {
      loadData();
    }
    // eslint-disable-next-line
  }, [itemUpdateProps.isSuccess]);

  const fields = {
    id: {
      title: 'ID',
      sorter: true,
      width: 80,
    },
    name: {
      title: 'Название',
      sorter: true,
      render: (val, item) => (<ExchangeValue detail={item} asLink={true} isBack={true} />),
    },
    code: {
      title: 'Код',
      sorter: true,
      width: 130,
    },
    dateSynced: {
      title: 'Дата синхронизации',
      sorter: true,
      width: 130,
      responsive: ['md'],
      render: (val, item) => (<DateTimeValue size="14" date={val} />),
    },
    tradingDay: {
      title: 'Торговый день',
      sorter: true,
      width: 130,
      render: (val, item) => <TradingDay tradingDay={val} />,
    },
    isForRobot: {
      title: 'Включено',
      sorter: true,
      width: 90,
      render: (val, item) => (<OnOff detail={item} fieldName="isForRobot" pkField="code" itemUpdate={itemUpdate} itemUpdateProps={itemUpdateProps} />),
    },
  };

  function loadData() {
    tableRef.current && tableRef.current.loadData();
  }
  useImperativeHandle(ref, () => ({ loadData }));

  return (
    <TableList
      ref={tableRef}
      fields={fields}
      columnList={columnList || defaultColumnList}
      objectList={objectList}
      allCount={allCount}
      queryKey="Exchange"

      getObjectList={getObjectList}
      getObjectListProps={getObjectListProps}
      onEdit={onEdit}

      topActions={topActions}
      showSearch={true}
      rowSelection={false}

      filterFields={[
        {
          label: 'Включено',
          field: 'isForRobot',
          component: FilterItemBool,
        },
      ]}
    />
  );
});

export default ExchangeList;
